import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { textFieldStyle } from "../../../Data/Interfaces";
import { inputPasswordProps } from "./InputPasswordProps";

function InputPassword({
  label,
  setValue,
  required,
  error,
  errorMessage,
  style
}: inputPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <TextField
      id="outlined-basic"
      required={required}
      error={error}
      helperText={errorMessage}
      label={label}
      variant="outlined"
      type={showPassword ? "text" : "password"}
      style={style}
      className="ButtonSizes"
      // scris si background
      sx={textFieldStyle}
      //placeholder
      InputLabelProps={{
        style: {
          color: "gray",
          fontFamily: "Inter",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              style={{ color: "#FFD15C", backgroundColor: "#383838" }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        style: {
          backgroundColor: "#383838",
        },
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default InputPassword;
