import { FC, useEffect, useState } from "react";
import Loading from "../../utils/loading/Loading";
import TabPlayersAdmin from "./TabPlayersAdmin";
import TabPlayersUser from "./TabPlayersUser";
import { useQuery } from "react-query";
import { getPlayersApi } from "../../../network/playersapi";
import { PlayerData } from "./types/PlayerData";
import { EventDataProps } from "../types";

export const TabPlayers: FC<EventDataProps> = ({ eventData, isAdmin, session }) => {
    const [players, setPlayers] = useState([]);
    const [event] = useState(eventData);
    
    const eventCode = window.location.pathname.split("/")[2];

    const { data, isLoading, error } = useQuery([`get-players`, eventCode], () => getPlayersApi(eventCode, session), {
        enabled: !!session
    });

    useEffect(() => {
        if (data) {
            setPlayers(data.data);
        }
    }, [data]);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    return (
        <div className="TabContent">
            {isLoading ? <Loading isLoading={isLoading} /> : isAdmin ? <TabPlayersAdmin playersData={players as PlayerData[]} eventData={event} session={session} /> : <TabPlayersUser playersData={players as PlayerData[]} eventData={event} session={session}/>}
        </div>
    );
};

export default TabPlayers;
