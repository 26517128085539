import "../../../css/TabPlayers.css";
import { FC } from "react";
import { TeamData, TeamsProps } from "./types/TeamData";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import triangle from "../../../Images/triangle.png";
import "../../../css/TabRounds.css";

export const TabTeamsUser: FC<TeamsProps> = ({ teamsData, eventData }) => {
  return (
    <>
      {eventData.adminApproval && (
        <div className="AdminApproval">
          Ask Organizer to add teams and team members.
        </div>
      )}
      <div className="TableTeams">
        {teamsData.map((row: TeamData) => (
          <Collapsible
            key={row.username}
            classParentString="PlayersInfoCollapsible"
            trigger={
              <div key={row.username} className="TableTeamsRow">
                <div className="TableCell">{row.username}</div>
                <a className="ArrowButton">
                  <img className="ProfileArrow" src={triangle} />
                </a>
              </div>
            }
          >
            <div className="PlayerInfo">
              {row.players.length > 0 ? (
                row.players.map((player: string) => (
                  <div key={player} className="TableTeamsRow">
                    <div className="TableCell">{player}</div>
                  </div>
                ))
              ) : (
                <div className="EmptyList">Team is empty.</div>
              )}
            </div>
          </Collapsible>
        ))}
      </div>
    </>
  );
};

export default TabTeamsUser;
