import { useState, FC, useEffect } from "react";
import FormButton from "../utils/formButton/FormButton";
import Input from "../utils/input/Input";
import logo from "../../Images/boardgame.png";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { getEventApi } from "../../network/eventapi";
import { SessionProps } from "./types/SessionProps";

export const JoinEvent: FC<SessionProps> = ({ session }) => {
    const [codeError, setCodeError] = useState(false);
    const [helperCode, setHelperCode] = useState("");
    const [eventCode, setEventCode] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
      setCodeError(false);
    }, [eventCode])

    const { mutate: getEvent } = useMutation(() => getEventApi(eventCode, session), {
        onSuccess: () => {
            navigate(`/events/${eventCode}`);
        },
        onError: (err: { response: { data: { statusCode: number}}}) => {
            console.log(err);
            setCodeError(true);
            if(err.response.data.statusCode === 403){
                setHelperCode("Forbidden.");
            } else {
                setHelperCode("Event code does not exist.");
            }
        },
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        getEvent();
    };

    return (
        <>
            <div className="LogoImage MarginTop50">
                <a href="/events"><img src={logo} alt="Logo" /></a>
            </div>
            <div className="IntroEvents">Join Event</div>
            <form onSubmit={onSubmit} className="FormCss">
                <Input label="Type Code" setValue={(eventCode: string) => { setEventCode(eventCode.toLowerCase()) }} required error={codeError} />
                {codeError && <div className="ErrorStyle">{helperCode}</div>}

                <FormButton label="Join" />
            </form>
        </>
    );
};

export default JoinEvent;
