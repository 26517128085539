import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Box, Checkbox, FormControlLabel, Link, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

import UserPool from "../../UserPool";
import { genders } from "../../Data/calendar";
import "../../css/RegisterPage.css";
import FormButton from "../utils/formButton/FormButton";
import DialogMenu from "../utils/dialogMenu/DialogMenu";
import Input from "../utils/input/Input";
import InputPassword from "../utils/inputPassword/InputPassword";
import InputSelect from "../utils/inputSelect/InputSelect";
import PageFooter from "../utils/footer/PageFooter";
import logo from "../../Images/boardgame.png";

function Register() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [givenName, setGivenName] = useState("");
    const [gender, setGender] = useState(genders[0].value);

    const [consentColor, setConsentColor] = useState({
        color: "#FFD15C",
        "&.Mui-checked": {
            color: "#FFD15C",
        },
    })
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [usernameExistsError, setUsernameExistsError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [unknownError, setUnknownError] = useState(false);
    const [submitCheck, setSubmitCheck] = useState(false);
    const [createdAccount, setCreatedAccount] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [birthdateError, setBirthdateError] = useState(false);

    useEffect(() => {
        if (username.length > 25) {
            setUsernameError(true);
        } else {
            setUsernameError(false);
            setUsernameExistsError(false);
        }
    }, [username]);

    useEffect(() => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.length > 0) {
            if (email.match(mailformat)) {
                setEmailError(false);
                setSubmitCheck(false);
            } else {
                setEmailError(true);
                setSubmitCheck(true);
            }
        }
    }, [email]);

    useEffect(() => {
        if (password.length < 7 && password.length > 0) {
            setPasswordError(true);
            setSubmitCheck(true);
        } else {
            setPasswordError(false);
            setSubmitCheck(false);
        }
    }, [password]);

    useEffect(() => {
        if (day.length !=2 && day.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [day]);

    useEffect(() => {
        if (month.length != 2 && month.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [month]);

    useEffect(() => {
        if (year.length != 4 && year.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [year]);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if(!privacyPolicy || !termsAndConditions){
            setConsentColor({
                color: "red",
                "&.Mui-checked": {
                    color: "#FFD15C",
                },
            });
            return;
        }

        const attributeList: CognitoUserAttribute[] = [];
        attributeList.push(
            new CognitoUserAttribute({
                Name: "email",
                Value: email,
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: "family_name",
                Value: familyName,
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: "given_name",
                Value: givenName,
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: "gender",
                Value: gender,
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: "birthdate",
                Value: day + "-" + month + "-" + year,
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: "custom:privacy_policy",
                Value: privacyPolicy.toString(),
            })
        );
        attributeList.push(
            new CognitoUserAttribute({
                Name: "custom:terms_and_conditions",
                Value: termsAndConditions.toString(),
            })
        );
        UserPool.signUp(username, password, attributeList, [], (err: any, data: any) => {
            if(data){
                setCreatedAccount(true);
            } else if (err?.toString().includes("UsernameExistsException")) {
                setUsernameExistsError(true);
            } else if (err?.toString().includes("InvalidPasswordException")) {
                setPasswordError(true);
            } else if (err?.toString().includes("birthdate")) {
                setBirthdateError(true);
            } else if (err?.toString().includes("Value at 'username' failed to satisfy constraint")) {
                setUsernameError(true);
            } else {
                setUnknownError(true);
            }
        });
    };

    return (
        <div className="RegisterPage">
            <DialogMenu
                isOpen={createdAccount}
                setOpen={setCreatedAccount}
                path="/login"
                description="Please check your email to validate this account."
            />
            <DialogMenu
                isOpen={unknownError}
                setOpen={setUnknownError}
                description="An unknown error happend. Please try to register again."
            />
            <a href="/events"><img className="LogoDesktop" src={logo} alt="Logo" /></a>

            <div className="RegisterPageForm PaddingTopRegister">
                <div className="IntroRegister">Register your account</div>

                <form onSubmit={onSubmit} className="FormCss">
                    <Input label="Username" required setValue={setUsername} error={usernameError} />
                    {usernameError && <div className="ErrorStyle">Username is too long, has spaces or invalid characters.</div>}
                    {usernameExistsError && <div className="ErrorStyle">Username already exists.</div>}
                    <Input label="Email" required setValue={setEmail} error={emailError} />
                    {emailError && <div className="ErrorStyle">Email is not valid.</div>}

                    <InputPassword label="Password" required setValue={setPassword} error={passwordError} />
                    {passwordError && <div className="ErrorStyle">Password has to be at least 7 characters.</div>}

                    <div className="BirthdateRegisterLabel">Birthday</div>
                    <div className="BirthdateRegister">
                        <Input type="number" required label="Day" error={birthdateError} setValue={setDay} style={{ width: "25%", display: "flex" }} />

                        <Input type="number" required label="Month" error={birthdateError} setValue={setMonth} style={{ width: "30%", display: "flex" }} />
                        <Input type="number" required label="Year" error={birthdateError} setValue={setYear} style={{ width: "40%", display: "flex" }} />
                    </div>
                    {birthdateError && <div className="ErrorStyle">Birthdate format must be DD-MM-YYYY.</div>}

                    <Input label="Family Name" required setValue={setFamilyName} />

                    <Input label="Given Name" required setValue={setGivenName} />

                    <InputSelect value={genders} selectedValue={gender} required label="Gender" setValue={setGender} style={{ width: "284px", height: "52px", marginBottom: "10px" }} />

                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <FormControlLabel
                            style={{ marginBottom: "-10px" }}
                            label={<Typography style={{ fontSize: "11px", fontFamily: "Inter", fontStyle: "normal", fontWeight: 400 }}>I have read the Privacy and Cookie Policy</Typography>}
                            control={
                                <Checkbox
                                    sx={consentColor}
                                    checked={privacyPolicy}
                                    onChange={() => {
                                        setPrivacyPolicy((prev) => !prev);
                                    }}
                                />
                            }
                        />
                        <FormControlLabel
                            style={{ marginBottom: "10px" }}
                            label={<Typography style={{ fontSize: "11px", fontFamily: "Inter" }}>I agree to the Terms and Conditions</Typography>}
                            control={
                                <Checkbox
                                    sx={consentColor}
                                    checked={termsAndConditions}
                                    onChange={() => {
                                        setTermsAndConditions((prev) => !prev);
                                    }}
                                />
                            }
                        />
                    </Box>

                    <FormButton label="Register" disabled={submitCheck} />
                </form>

                <div className="AlreadyHaveAnAccount">
                    Already have an account?{" "}
                    <Link href="/login" style={{ color: "#FFD15C", textDecoration: "none" }}>
                        Login
                    </Link>
                </div>
            </div>
            <PageFooter/>
        </div>
    );
}

export default Register;
