import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useState } from "react";
import Input from "../input/Input";
import { GenereateRoundRequest } from "../../event/tabRounds/types/GenerateRound";
import FormButton from "../formButton/FormButton";
import { Typography } from "@mui/material";

interface GenerateRoundPropTypes {
    roundNumber: string;
    openDialog: boolean;
    generateRound: any;
    handleAddTable: any;
    handleClose: any;
}

export const FormDialogRoundSettings: FC<GenerateRoundPropTypes> = ({ handleAddTable, generateRound, roundNumber, openDialog, handleClose }) => {
    const [maxPlayers, setMaxPlayers] = useState(4);
    const [minPlayers, setMinPlayers] = useState(3);
    const [noOfGroups, setNoOfGroups] = useState(8);

    const handleGenerateRound = (e: any) => {
        e.preventDefault();

        let data: GenereateRoundRequest = {
            roundNumber: roundNumber,
            maxNoOfPlayersAtTable: maxPlayers,
            minNoOfPlayersAtTable: minPlayers,
            noOfGroups: noOfGroups,
        };
        generateRound(data);
        handleClose();
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#383838", borderRadius: "10px", display: "flex", justifyContent: "center", flexWrap: "wrap" } }}>
                <DialogTitle style={{ display: "flex", justifyContent: "center", color: "#FFD15C", fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "16px" }}>
                    ROUND {roundNumber?.replace("round", "")} SETTINGS
                </DialogTitle>
                <form
                    onSubmit={(e) => {
                        handleAddTable(e);
                        handleClose();
                    }}
                    className="EventSettingsForm"
                >
                    <FormButton label="+ Add New Table" />
                </form>
                <Typography style={{ textAlign: "center", color: "white", fontFamily: "Inter", fontSize: "12px" }} className="NormalFont">
                    Or
                </Typography>
                <form onSubmit={handleGenerateRound} className="EventSettingsForm">
                    <Input label="Max No. Of Players" defaultValue={maxPlayers.toString()} required setValue={setMaxPlayers} />
                    <Input label="Min No. Of Players" defaultValue={minPlayers.toString()} required setValue={setMinPlayers} />
                    <Input label="No. Of Groups" defaultValue={noOfGroups.toString()} required setValue={setNoOfGroups} />
                    <FormButton label="Generate Round" style={{ marginBottom: "10px" }} />
                </form>
            </Dialog>
        </>
    );
};
