import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useState } from "react";
import Input from "../input/Input";
import FormDialogButton from "./FormDialogButton";
import { useMutation } from "react-query";
import { addPlayerApi } from "../../../network/playersapi";
import { AccountJWT } from "../userContext/UserContextProps";
import { AddPlayerRequest } from "../../event/tabPlayers/types/AddPlayerRequest";

interface AddPlayerRoundPropTypes {
    eventCode: string;
    openDialog: boolean;
    handleClose: any;
    session: AccountJWT | null;
}

export const FormDialogAddPlayer: FC<AddPlayerRoundPropTypes> = ({ openDialog, handleClose, eventCode, session }) => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [phone, setPhone] = useState("");
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const { mutate: addPlayerToEvent } = useMutation((variables: AddPlayerRequest) => addPlayerApi(eventCode, variables, session));

    const handleAddPlayer = (e: any) => {
        e.preventDefault();

        let data: AddPlayerRequest = {
            username,
            email,
            gender,
            phone,
            givenName,
            familyName
        }

        addPlayerToEvent(data);
        handleClose();
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#383838" } }}>
                <DialogTitle style={{ display: "flex", justifyContent: "center", color: "#FFD15C", fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "16px" }}>
                    Add Player
                </DialogTitle>
                <form onSubmit={handleAddPlayer}>
                    <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                        <Input label="Username" required setValue={setUsername} />
                    </DialogContent>
                    <DialogContent style={{ display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <Input label="Given Name" setValue={setGivenName} />
                    </DialogContent>
                    <DialogContent style={{ display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <Input label="Family Name" setValue={setFamilyName} />
                    </DialogContent>
                    <DialogContent style={{ display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <Input label="Email" setValue={setEmail} />
                    </DialogContent>
                    <DialogContent style={{ display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <Input label="Phone" setValue={setPhone} />
                    </DialogContent>
                    <DialogContent style={{ display: "flex", justifyContent: "center", marginTop: "-30px" }}>
                        <Input label="Gender" setValue={setGender} />
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "-20px" }}>
                        <FormDialogButton label="Add" />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
