import { useContext, useEffect, useState } from "react";
import "../../css/EventsPage.css";
import "../../css/NavbarPage.css";
import Navbar from "../utils/navbar/Navbar";
import { AccountContext } from "../utils/userContext/UserContext";
import JoinEvent from "./JoinEvent";
import CreateEvent from "./CreateEvent";
import { useNavigate } from "react-router-dom";
import Loading from "../utils/loading/Loading";
import PageFooter from "../utils/footer/PageFooter";

function Events() {
    const [addCloseButton, setAddCloseButton] = useState(false);
    const { getSession, session, isLoading: sessionLoading } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSession = async () => {
            await getSession();
        };

        fetchSession().catch(() => {
            if (!session) {
                navigate("/login");
            }
        });
    }, []);

    if(!session) {
        return (<Loading isLoading={sessionLoading}/>)
    }

    return (
        <div className="EventsPage">
            <div className="EventsPageWeb">
                    <>
                        <Navbar isOpen={addCloseButton} setOpen={setAddCloseButton} />

                        {addCloseButton ? <CreateEvent session={session} /> : <JoinEvent session={session} />}
                    </>
            </div>
            <PageFooter/>
        </div>
    );
}

export default Events;
