import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useState } from "react";
import Input from "../input/Input";
import FormDialogButton from "./FormDialogButton";
import { useMutation } from "react-query";
import { upsertTeamApi } from "../../../network/teamsapi";
import { AccountJWT } from "../userContext/UserContextProps";
import { UpsertTeam } from "../../event/tabTeams/types/TeamData";

interface AddPlayerRoundPropTypes {
  eventCode: string;
  openDialog: boolean;
  handleClose: any;
  session: AccountJWT | null;
}

export const FormDialogAddTeam: FC<AddPlayerRoundPropTypes> = ({
  openDialog,
  handleClose,
  eventCode,
  session,
}) => {
  const [username, setusername] = useState("");
  const { mutate: addPlayerToEvent } = useMutation((variables: UpsertTeam) =>
    upsertTeamApi(eventCode, variables, session)
  );

  const handleAddTeam = (e: any) => {
    e.preventDefault();

    let data: UpsertTeam = {
      username,
      players: [],
      isConfirmed: true,
    };

    addPlayerToEvent(data);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{ style: { backgroundColor: "#383838" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#FFD15C",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
          }}
        >
          Add Player
        </DialogTitle>
        <form onSubmit={handleAddTeam}>
          <DialogContent style={{ display: "flex", justifyContent: "center" }}>
            <Input label="Team Name" required setValue={setusername} />
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop: "-20px",
            }}
          >
            <FormDialogButton label="Add" />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
