import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { Table } from './Table';
import { useMutation, useQuery } from 'react-query';
import { UpdateRoundRequest } from './types/UpdateRoundRequest';
import { deleteItemApi, generateRoundsApi, getRoundApi, updateRoundApi } from '../../../network/roundsapi';
import { AccountJWT } from '../../utils/userContext/UserContextProps';
import Loading from '../../utils/loading/Loading';
import triangle from '../../../Images/triangle.png';
import '../../../css/TabRounds.css';
import { FormDialogRoundSettings } from '../../utils/formDialog/FormDialogRoundSettings';
import { GenereateRoundRequest } from './types/GenerateRound';
import { AdminActionButtonSmall } from '../../utils/adminActionButton/AdminActionButtonSmall';
import { RemoveItem } from './types/RemoveItem';
import edit from '../../../Images/settings.png';

interface RoundsPropTypes {
    //rounds: { round: [{ players: []; table: string; round: string;}] };
    index: number;
    eventCode: string;
    isAdmin: boolean;
    teamEvent: boolean;
    session: AccountJWT | null;
}
export const Rounds: FC<RoundsPropTypes> = ({ index, eventCode, isAdmin, session, teamEvent }) => {
    // const tables = rounds.round;
    const { mutate: generateRound, isLoading: generateLoading } = useMutation((data: GenereateRoundRequest) =>
        generateRoundsApi(data, eventCode, session)
    );
    const roundNumber = `round${index + 1}`;
    const [tables, setTables] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const { data, isLoading, error } = useQuery(['get-round', eventCode, roundNumber], () =>
        getRoundApi(eventCode, session, roundNumber)
    );

    const { mutate: addTable, isLoading: addTableLoading } = useMutation((variables: UpdateRoundRequest) =>
        updateRoundApi(eventCode, variables, session)
    );
    const { mutate: deleteItem, isLoading: deleteItemLoading } = useMutation((data: RemoveItem) =>
        deleteItemApi(eventCode, data, session)
    );

    useEffect(() => {
        if (data) {
            const tableData = data.data;
            setTables(tableData);
        }
    }, [data]);

    const handleAddTable = (e: any) => {
        e.preventDefault();

        const items = {
            round: roundNumber,
            table: `table${data?.data.length + 1}`,
        };

        const newTable = {
            items,
            roundNumber: roundNumber,
            tableNumber: items.table,
        };

        addTable(newTable);
    };

    const handleDeleteTable = (tableNumber: string) => {
        let data = {
            pk: `event#${eventCode}`,
            sk: `round#${roundNumber}#${tableNumber}`,
            roundNumber: roundNumber,
        };

        deleteItem(data);
    };

    if (isLoading || generateLoading || deleteItemLoading || addTableLoading) {
        return <Loading isLoading={isLoading || generateLoading || deleteItemLoading || addTableLoading} />;
    }

    return (
        <>
            <FormDialogRoundSettings
                handleAddTable={handleAddTable}
                generateRound={generateRound}
                roundNumber={roundNumber}
                openDialog={openDialog}
                handleClose={handleCloseDialog}
            />
            <Collapsible
                trigger={
                    <Box
                        sx={{
                            backgroundColor: '#383838',
                            width: '100%',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '12px',
                                color: '#FFD15C',
                                marginLeft: 2,
                            }}
                        >
                            Round {index + 1}
                        </Typography>
                        <Box>
                            {isAdmin && (
                                <a>
                                    <img
                                        className="SettingsIcon"
                                        src={edit}
                                        onClick={() => {
                                            setOpenDialog(true);
                                        }}
                                    />
                                </a>
                            )}
                            <a className="ArrowButton">
                                <img id={index.toString()} className="RoundArrow" src={triangle} />
                            </a>
                        </Box>
                    </Box>
                }
            >
                {tables.length > 0 ? (
                    tables.map((table: any, index: number) => {
                        return (
                            <Table
                                key={index}
                                table={table}
                                eventCode={eventCode}
                                roundNumber={roundNumber}
                                isAdmin={isAdmin}
                                session={session}
                                handleDeleteTable={handleDeleteTable}
                                teamEvent={teamEvent}
                            />
                        );
                    })
                ) : (
                    <div className="EmptyList">Round is not generated yet.</div>
                )}
            </Collapsible>
        </>
    );
};
