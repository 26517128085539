import { Button } from "@mui/material";
import { AdminButtonProps } from "../adminActionButton/AdminButtonProps";

export const AdminSmallButton = ({label, handleGenerate, styles}:AdminButtonProps) => {
    let defaultStyles = {
        borderRadius: "5px",
        backgroundColor: "#FF5C5C",
        borderColor: "#FF5C5C",
        color: "#262626",
        fontWeight: 600,
        fontSize: "8px",
        fontFamily: "Inter",
        padding: 1,
        "&:hover": {
            backgroundColor: "#FF5C5C",
            borderColor: "#FF5C5C",
            color: "#262626",
        },
    };

    if(styles) {
        Object.assign(defaultStyles, styles);
    }
    return (
        <Button
            sx={defaultStyles}
            onClick={handleGenerate}
            variant="outlined"
        >
            {label}
        </Button>
    );
};
