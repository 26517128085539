import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import UserPool from "../../UserPool";
import logo from "../../Images/boardgame.png";
import "../../css/RegisterPage.css";
import DialogMenu from "../utils/dialogMenu/DialogMenu";
import InputPassword from "../utils/inputPassword/InputPassword";
import Input from "../utils/input/Input";
import HaveNotAccount from "../utils/authNavigation/AuthNavigation";
import FormButton from "../utils/formButton/FormButton";
import { Link } from "@mui/material";
import PageFooter from "../utils/footer/PageFooter";

function ForgotPassword() {
    const [username, setUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [helperEmail, setHelperEmail] = useState("");
    const [submitCheck, setSubmitCheck] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [helperCode, setHelperCode] = useState("");
    const [resetForm, setResetForm] = useState(false);
    const [changedPassword, setChangedPassword] = useState(false);

    useEffect(() => {
        setCodeError(false);
        setHelperCode("");
    }, [verificationCode]);

    useEffect(() => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (username.length > 0) {
            if (username.match(mailformat)) {
                setUsernameError(false);
                setHelperEmail("");
                setSubmitCheck(false);
            } else {
                setUsernameError(true);
                setHelperEmail("Invalid email adress.");
                setSubmitCheck(true);
            }
        }
        if (username.length === 0) {
            setUsernameError(false);
            setHelperEmail("");
            setSubmitCheck(false);
        }
    }, [username]);

    const onSubmit = (e: any) => {
        e.preventDefault();

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        cognitoUser.forgotPassword({
            onSuccess: function (result) {
                setResetForm(true);
            },
            onFailure: function (err) {
                setUsernameError(true);
                if (err.message.startsWith("Attempt")) {
                    setHelperEmail("Attempt limit exceeded, try again later.");
                } else {
                    setHelperEmail("Invalid email adress.");
                }
            },
        });
    };

    const onSubmitNewPassword = (e: any) => {
        e.preventDefault();

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onFailure(err) {
                    setCodeError(true);
                    setHelperCode("Invalid verification code");
                    reject(err);
                },
                onSuccess: function (result) {
                    setChangedPassword(true);
                },
            });
        });
    };

    return (
        <div className="LoginPage">
            <DialogMenu isOpen={changedPassword} setOpen={setChangedPassword} path="/login" description="Password changed successfully." />

            <div className="LogoImage MarginTopLogin">
                <a href="/events"><img src={logo} alt="Logo" /></a>
            </div>

            <div className="LoginPageForm">
            <div className="IntroLogin">
                    <div style={{ fontSize: "20px", marginBottom: "43px" }}>Reset your password</div>
                </div>
                {resetForm ? (
                    <form onSubmit={onSubmitNewPassword} className="FormCss">
                        <InputPassword label="New Password" required setValue={setNewPassword} />

                        <Input label="Verification Code" required setValue={setVerificationCode} error={codeError} />
                        {codeError && <div className="ErrorStyle">{helperCode}</div>}

                        <FormButton label="Change Password" />
                    </form>
                ) : (
                    <form onSubmit={onSubmit} className="FormCss">
                        <Input label="Email" required setValue={setUsername} error={usernameError}/>
                        {usernameError && <div className="ErrorStyle">{helperEmail}</div>}

                        <FormButton label="Reset password" disabled={submitCheck}/>
                    </form>
                )}

                <div className="DontHaveAnAccount">
                    Have an account?{" "}
                    <Link href="/login" style={{ color: "#FFD15C", textDecoration: "none" }}>
                        Login
                    </Link>
                </div>
            </div>

            <PageFooter/>
        </div>
    );
}

export default ForgotPassword;
