import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { createContext, useState } from "react";
import UserPool from "../../../UserPool";
import { AccountJWT } from "./UserContextProps";

export const AccountContext = createContext<{
    authenticate: Function;
    getSession: Function;
    logout: Function;
    session: AccountJWT | null;
    isLoading: boolean;
}>({
    authenticate: () => {},
    getSession: () => {},
    logout: () => {},
    session: null,
    isLoading: false,
});

export const UserContext = ({ children }: any) => {
    const [session, setSession] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // const getSession2 = async () => {
    //   const user = UserPool.getCurrentUser();

    //   if (user) {
    //     user.getSession((err: any, sessionData: any) => {
    //       if (err) {
    //         navigate("/login");
    //       } else {
    //         setSession(sessionData);
    //       }
    //     });
    //   };
    // };

    const getSession = async () => {
        if (session) {
            return;
        }

        setIsLoading(true);
        await new Promise<void>((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                user.getSession((err: any, newSession: any) => {
                    if (err) {
                        setIsLoading(false);
                        reject(err);
                    } else {
                        setSession(newSession);
                        setIsLoading(false);
                        resolve();
                    }
                });
            } else {
                setIsLoading(false);
                reject();
            }
        });
        setIsLoading(false);
    };
    const authenticate = async (Username: string, Password: string) => {
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool: UserPool,
            });

            const authDetails = new AuthenticationDetails({
                Username,
                Password,
            });

            user.authenticateUser(authDetails, {
                onSuccess: (result) => {
                    getSession();
                    resolve(result);
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    resolve(data);
                },
            });
        });
    };

    const logout = () => {
        const user = UserPool.getCurrentUser();
        user!.signOut();
        window.location.href = "/login";
    };

    return <AccountContext.Provider value={{ authenticate, getSession, logout, session, isLoading }}>{children}</AccountContext.Provider>;
};
