export const eventSystems = [
    {
        value: 'CatanOfficialTournament',
        label: 'Catan Official Tournament',
    },
    {
        value: 'CarcassonneOfficialTournament',
        label: 'Carcassonne Official Tournament',
    },
    {
        value: 'Generic',
        label: 'Generic',
    },
];
