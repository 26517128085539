import { GenereateRoundRequest } from "../Components/event/tabRounds/types/GenerateRound";
import { RemoveItem } from "../Components/event/tabRounds/types/RemoveItem";
import { UpdateRoundRequest } from "../Components/event/tabRounds/types/UpdateRoundRequest";
import { AccountJWT } from "../Components/utils/userContext/UserContextProps";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

export const generateRoundsApi = (data: GenereateRoundRequest, eventCode: string, session: AccountJWT | null) => {
    return axios.post(BASE_URL + `/events/${eventCode}/rounds`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const getRoundsApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.get(BASE_URL + `/events/${eventCode}/rounds`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const getRoundApi = (eventCode: string, session: AccountJWT | null, roundNumber: string) => {
    return axios.get(BASE_URL + `/events/${eventCode}/rounds/${roundNumber}`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const updateRoundApi = (eventCode: string, data: UpdateRoundRequest, session: AccountJWT | null) => {
    return axios.put(BASE_URL + `/events/${eventCode}/rounds`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const deleteItemApi = (eventCode: string, data: RemoveItem, session: AccountJWT | null) => {
    return axios.delete(BASE_URL + `/events/${eventCode}`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
        data,
    });
};

export const deleteRoundApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.delete(BASE_URL + `/events/${eventCode}/rounds`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};