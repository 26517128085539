import { UpdateUser } from '../Components/profile/types/UpdateUser';
import { AccountJWT } from '../Components/utils/userContext/UserContextProps';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

export const getUserApi = (username: string, page: string, session: AccountJWT | null) => {
    return axios.get(BASE_URL + `/users/${username}`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
        params: {
            page: page,
        },
    });
};

export const updateUserApi = (username: string, data: UpdateUser, session: AccountJWT | null) => {
    return axios.patch(BASE_URL + `/users/${username}`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};
