import { IconButton, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Link } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "../../../css/NavbarPage.css";
import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import { NavbarProps } from "./NavbarProps";
import { useNavigate } from "react-router-dom";
import profileLogo from "../../../Images/profile.png";
import close from "../../../Images/cross.png";
import plus from "../../../Images/plus.png";
import { AccountContext } from "../userContext/UserContext";

function Navbar({ closeButton, isOpen, setOpen }: NavbarProps) {
    const { session, logout } = useContext(AccountContext);

    const navigate = useNavigate();
    const handleClickAddClose = () => setOpen((prev: boolean) => !prev);
    const handleClickClose = () => navigate("/events");
    const handleMouseDownAddClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    /* Menu const and functions */
    const [openUser, setOpenUser] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpenUser((prevOpen) => !prevOpen);
    };
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenUser(false);
    };

    const handleLogout = () => {
        logout();
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenUser(false);
        } else if (event.key === "Escape") {
            setOpenUser(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openUser);

    return (
        <div className="Navbar">
            {closeButton ? (
                <button onClick={handleClickClose} onMouseDown={handleMouseDownAddClose} className="NavbarButton">
                    <a><img className="NavbarCross" src={close} alt="Logo" /></a>
                </button>
            ) : (
                <button onClick={handleClickAddClose} onMouseDown={handleMouseDownAddClose} className="NavbarButton">
                    {isOpen ? <a><img className="NavbarCross" src={close} alt="Logo" /></a> : session?.idToken.payload[`custom:is_admin`] && <a><img className="NavbarCross" src={plus} alt="Logo" /></a>}
                </button>
            )}

            <button
                onClick={handleToggle}
                ref={anchorRef}
                id="composition-button"
                aria-controls={openUser ? "composition-menu" : undefined}
                aria-expanded={openUser ? "true" : undefined}
                aria-haspopup="true"
                className="NavbarButton"
            >
                <a><img className="NavbarProfile" src={profileLogo} alt="Logo" /></a>
            </button>
            <Popper open={openUser} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition disablePortal onResize={undefined} onResizeCapture={undefined}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-end" ? "left top" : "left bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={openUser}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                    sx={{ backgroundColor: "#383838", color: "gray" }}
                                >
                                    <a className="MenuItemFont" onClick={() => { navigate(`/users/${session?.idToken.payload[`cognito:username`]}`); } } style={{ textDecoration: "none", color: "#808080" }}>
                                        <MenuItem className="MenuItemFont">Profile</MenuItem>
                                    </a>
                                    <MenuItem onClick={handleLogout} className="MenuItemFont">
                                        Logout
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

export default Navbar;
