import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Events from './Components/event/Events';
import ForgotPassword from './Components/auth/ForgotPassword';
import Login from './Components/auth/Login';
import Register from './Components/auth/Register';
import { UserContext } from './Components/utils/userContext/UserContext';
import PlayEvent from './Components/event/PlayEvent';
import { QueryClient, QueryClientProvider } from 'react-query';
import Profile from './Components/profile/Profile';
import TermsAndConditions from './Components/TermsAndConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
    const bgeTheme = createTheme({
        palette: {
            primary: {
                main: '#FFD15C',
            },
            secondary: {
                main: '#FFFFFF',
            },
        },
    });
    const queryClient = new QueryClient();
    return (
        <ThemeProvider theme={bgeTheme}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <BrowserRouter>
                    <UserContext>
                        <Routes>
                            <Route path="." element={<App />} />
                            <Route path="/events" element={<Events />} />
                            <Route path="/events/:eventCode" element={<PlayEvent />} />
                            <Route path="/users/:userName" element={<Profile />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/forgotpassword" element={<ForgotPassword />} />
                            <Route path="/termsandconditions" element={<TermsAndConditions />} />
                            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                            <Route path="*" element={<Navigate to="/events" replace />} />
                        </Routes>
                    </UserContext>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
