import logo from "../Images/boardgame.png";
import PageFooter from "./utils/footer/PageFooter";

export const TermsAndConditions = () => {
    return (
        <div className="LoginPage">
        <div className="LogoImage MarginTop50">
            <a href="/events">
                <img src={logo} alt="Logo" />
            </a>
        </div>
            <div className="EventsPageWeb NormalFont">
                <div className="TermsAndConditions">
                    <h1>Terms And Conditions</h1>

                    <div className="TermsAndConditionsContent">
                        <p>
                            These Terms and Conditions (the “Terms”) constitute a legal agreement between you and Board Games Events, Lupusoru Alexandru Intreprindere Individuala, of Sat Dobrovăț,
                            Comuna Dobrovăț, DJ. 247, NR. CADASTRAL 62844-C, Judet lasi, Romania (“Board Games Events”, “we”, “us”, or “our”) governing the use our website and web application
                            (collectively “Services”). We license use of our Services to you on the basis of these Terms. We do not sell our Services to you, and we remain the owner of our Services at
                            all times.
                        </p>
                        <p>
                            <strong>1.</strong>
                            <strong>TERMS OF USE</strong> <strong></strong>
                        </p>
                        <p>
                            1.1. The provisions set out in these Terms govern your access to and your use of our Services and shall constitute a legally binding agreement between you and us. We may
                            change such terms from time to time and shall notify you accordingly if we do. If you do not agree to such terms, you must not use our Services.
                        </p>
                        <p>1.2. Subject to you agreeing to abide by these Terms, we hereby grant to you a revocable, non-exclusive, and non-transferable license to use our Services on these Terms.</p>
                        <p>
                            1.3. By registering for an Account, which involves providing us with certain mandatory and voluntary information as required for a successful registration and using our
                            Services, you agree and acknowledge that:
                        </p>
                        <p>1.3.1. you have read the terms set out in these Terms and agree to be bound by and comply with them; and</p>
                        <p>1.3.2. you shall ensure that all Users of your Account abide by these Terms.</p>
                        <p>
                            1.4. You are responsible for maintaining the confidentiality of your Account and you are responsible for all activities that occur under your Account. You agree that all
                            actions carried out by any person through your Account shall be deemed to be an act carried out by you, and you shall ensure that all persons who have access to and use
                            your Account are authorized to do so. We are not responsible for any loss, damage or liabilities arising as a result of or in connection with the wrongful, fraudulent or
                            illegal use of your Account.
                        </p>
                        <p>
                            1.5. We reserve the right to, without any notice, explanation, or liability and in our sole discretion, refuse to allow you or suspend your access to our Services or your
                            Account at any time, or remove or edit content (including content submitted by you) on our Services or on any of our affiliated websites (including social media pages).
                        </p>
                        <p>
                            1.6. We reserve the right to change, modify, suspend, or discontinue any portion of the Services or any other products, services, affiliated websites (including social
                            media pages) and/or other software provided by us in connection with any of the foregoing at any time. You agree that access to or operation of any of the foregoing may
                            from time to time be interrupted or encounter technical difficulties.
                        </p>
                        <p>
                            1.7. Save to the extent permitted by us in writing, you are not permitted to use, or submit any content to, our Services or any of our affiliated websites to advertise,
                            promote or market any products or services of any third party or yourself.
                        </p>
                        <p>
                            <strong>2.</strong> <strong>SUBSCRIPTION</strong>
                        </p>
                        <p>
                            2.1. Some Services may require payment of subscription fees and/or other ad-hoc or ancillary fees before you can access or use them (“Fees”). These Fees will be notified to
                            you through our Services.
                        </p>
                        <p>
                            2.2. If you purchase a recurring subscription from us, the subscription period for your Account shall be renewed automatically at the expiry of each subscription period,
                            until terminated successfully through our Services. By purchasing the recurring subscription, you authorize us or our related corporations to automatically charge the Fees:
                        </p>
                        <p>2.2.1. upon the commencement of your first subscription period, upon expiration of any applicable trial period or at a date otherwise indicated by us; and</p>
                        <p>2.2.2. on the renewal date of the subscription period thereafter, without any further action by you.</p>
                        <p>
                            2.3. Any Fees due in relation to your Account must be paid by their due date for payment, as notified to you through our Services or otherwise. Failure to make timely
                            payment of the Fees may result in the suspension or termination of your access to your Account and/or our Services or any of the Services.
                        </p>
                        <p>
                            2.4. Our Fees may be amended from time to time at our discretion. We will provide you reasonably advance written notice of any amendment of recurring Fees. Your continued
                            use of a recurring subscription will constitute acceptance of the amended Fees.
                        </p>
                        <p>2.5. You shall be responsible for any applicable taxes (including any goods and services tax) under these Terms.</p>
                        <p>
                            2.6. All payments shall be made by using the payment methods specified by us from time to time. You acknowledge and agree that you are subject to the applicable user
                            agreement of any third-party payment methods. We shall not be liable for any failure, disruption, or error in connection with your chosen payment method. We reserve the
                            right at any time to modify or discontinue, temporarily or permanently, any payment method without notice to you or giving any reason.
                        </p>
                        <p>
                            2.7. We must receive payment in full no later than the day on which such payment is required to be paid in immediately available and freely transferable funds, without any
                            restriction, condition, withholding, deduction, set-off or counterclaim whatsoever.
                        </p>
                        <p>
                            2.8. Unless otherwise notified in writing by us, termination of your Account for any reason whatsoever shall not entitle you to any refund of the Fees. If you cancel your
                            subscription to our Services, you may continue to access your Account until the expiry of the subscription period in which the cancellation occurred.
                        </p>
                        <p>
                            1.1. To the extent permitted by applicable law, payments are non-refundable. If you wish to cancel the Services, please contact us. We may, at our sole and absolute
                            discretion, offer a refund of Fees for a particular subscription period where no actions have been taken in respect of your Account and you have notified us in writing of
                            your intention to terminate your subscription within 72 hours of you taking out your subscription.
                        </p>
                        <p>
                            <strong>3.</strong> <strong>UPLOADING CONTENT TO OUR SERVICES</strong>
                        </p>
                        <p>
                            3.1. You irrevocably and unconditionally represent and warrant that any of your content uploaded to our Services complies with our Privacy Policies, Data Processing
                            Agreement, and any other applicable laws.
                        </p>
                        <p>3.2. You are fully responsible for your content uploaded to our Services. We will not be responsible, or liable to any third party, for:</p>
                        <p>3.2.1. the content or accuracy of any content or data uploaded by you, by us on your behalf, or any other user of our Services; or</p>
                        <p>3.2.2. the loss of any content or data provided to us by you. You should keep a record of all such content and data.</p>
                        <p>
                            3.3. We will only use the content uploaded by you for the purposes of carrying out the Services, carrying out our obligations in this Agreement and any other purpose
                            expressly set out in this Agreement or otherwise agreed between us. We will not otherwise disclose or distribute the content uploaded by you, save for when required by law,
                            a court of competent jurisdiction or any governmental or regulatory authority.
                        </p>
                        <p>
                            3.4. We may use the content uploaded by you for the purpose of data analytics or to implement artificial intelligence or machine learning. Any such content shall be
                            anonymised and used only for the purposes of improving the Services and our response to users of the Services.
                        </p>
                        <p>
                            3.5. We have the right to disclose your identity to any third party claiming that any content posted or uploaded by you to our Services constitutes a violation of their
                            rights under applicable law.
                        </p>
                        <p>
                            <strong>4.</strong> <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                        </p>
                        <p>
                            4.1. You acknowledge that all intellectual property rights in our Services anywhere in the world belong to us, that rights in our Services are licensed (not sold) to you,
                            and that you have no rights in, or to, our Services other than the right to use them in accordance with these Terms.
                        </p>
                        <p>
                            4.2. Any intellectual property rights in content uploaded by you to our Services shall continue to belong to you or their respective owners. You agree that you grant us a
                            royalty-free and non-exclusive license to use, reproduce, publish, and display such intellectual property rights for the purposes of performing the Services, promotional
                            purposes, internal administrative purposes and any other purposes set out in these Terms, including for the purpose of improving the Services and our responses to users of
                            the Services.
                        </p>
                        <p>4.3. You acknowledge that you have no right to have access to our Services in source code form.</p>
                        <p>4.4. Our status (and that of any identified contributors) as the authors of content on our Services must always be acknowledged.</p>
                        <p>
                            <strong>5.</strong> <strong>WARRANTIES</strong>
                        </p>
                        <p>
                            5.1. While we make all efforts to maintain the accuracy of the information on our Services, we provide the Services and all Related Content on an “as is” and “as available”
                            basis, unless otherwise specified in writing. We make no representations or warranties of any kind, express or implied, as to the operation of any of the foregoing, unless
                            otherwise specified in writing.
                        </p>
                        <p>
                            5.2. To the full extent permissible by law, we disclaim all warranties, express or implied, relating to our Services, including but not limited to implied warranties of
                            merchantability and fitness for a particular purpose. We do not warrant that the Services, the Related Content, or electronic communications sent by us are free of viruses
                            or other harmful components.
                        </p>
                        <p>
                            <strong>6.</strong> <strong>LIMITATION OF LIABILITY</strong>
                        </p>
                        <p>
                            6.1. We are not liable for the completeness, accuracy or correctness of any information uploaded on our Services and any Related Content. You expressly agree that your use
                            of the Services is at your sole risk.
                        </p>
                        <p>
                            6.2. You agree not to use the Services and the Related Content for any resale purposes, and we have no liability to you, whether in contract, tort (including negligence),
                            breach of statutory duty, or otherwise, arising under or in connection with these Terms (including but not limited to the use of, or inability to use, the Services or any
                            other website or software) for:
                        </p>
                        <p>6.2.1. loss of profits, sales, business, or revenue;</p>
                        <p>6.2.2. business interruption;</p>
                        <p>6.2.3. loss of anticipated savings;</p>
                        <p>6.2.4. loss or corruption of data or information;</p>
                        <p>6.2.5. loss of business opportunity, goodwill or reputation; or</p>
                        <p>6.2.6. any other indirect or consequential loss or damage.</p>
                        <p>6.3. Nothing in these Terms shall limit or exclude our liability for:</p>
                        <p>6.3.1. death or personal injury resulting from our negligence;</p>
                        <p>6.3.2. fraud; and/or</p>
                        <p>6.3.3. any other matter in respect of which we are prohibited under applicable law from limiting or excluding our liability.</p>
                        <p>6.4. Our Services is not intended to serve a record-keeping function and we shall not be liable for any loss of data or content.</p>
                        <p>
                            6.5. These Terms set out the full extent of our obligations and liabilities in respect of the supply of the Services. Except as expressly stated in these Terms, there are
                            no conditions, warranties, representations, or other terms, express or implied, that are binding on us. Any condition, warranty, representation, or other term concerning
                            the supply of the Services which might otherwise be implied into, or incorporated in, these Terms whether by statute, common law or otherwise, is excluded to the fullest
                            extent permitted by law.
                        </p>
                        <p>
                            <strong>7.</strong> <strong>INDEMNITY</strong>
                        </p>
                        <p>
                            You agree to indemnify and hold us, and our respective directors, officers, employees, agents and representatives, independent contractors, licensees, successors and
                            assigns harmless from and against all claims, losses, expenses, damages and costs (including but not limited to direct, incidental, consequential, exemplary and indirect
                            damages), and reasonable legal fees, resulting from or arising out of your act, default or omission, whether in your use of our Services, and/or any websites or software in
                            relation thereto or otherwise, and whether in respect of your breach of these Terms or any laws or regulations or otherwise.
                        </p>
                        <p>
                            <strong>8.</strong> <strong>OTHER IMPORTANT TERMS</strong>
                        </p>
                        <p>8.1. We may transfer our rights and obligations under these Terms to another organization, but this will not affect your rights or obligations under these Terms.</p>
                        <p>8.2. You may only transfer your rights or your obligations under these Terms to another person if we agree in writing.</p>
                        <p>8.3. No joint venture, partnership or agency or employment relationship has arisen by reason of these Terms.</p>
                        <p>
                            8.4. These Terms and any document expressly referred to in it constitutes the entire agreement between us regarding their subject matter, and supersedes and extinguishes
                            all previous agreements, promises, assurances, warranties, representations, and understandings between us, whether written or oral, relating to that subject matter. You
                            agree that you shall have no remedies in respect of any statement, representation, assurance, or warranty (whether made innocently or negligently) that is not set out in
                            these Terms, or any document expressly referred to in it. You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based
                            on any statement in these Terms or any document expressly referred to in it.
                        </p>
                        <p>
                            8.5. If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will
                            not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only
                            do so in writing, and that will not mean that we will automatically waive any later default by you.
                        </p>
                        <p>
                            8.6. Each of the conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining
                            conditions will remain in full force and effect.
                        </p>
                        <p>
                            8.7. These Terms, its subject matter and its formation, and any other disputes or claims in connection therewith, are governed by the law of Romania. In the event of any
                            such disputes or claims in connection with these Terms, you agree to first engage in good faith discussions with us to resolve such dispute or claim. If such dispute or
                            claim is not resolved within sixty (60) days, we both irrevocably submit to the exclusive jurisdiction of the courts of Iași County, Romania.
                        </p>
                    </div>
                </div>
            </div>

            <PageFooter />
        </div>
    );
};

export default TermsAndConditions;
