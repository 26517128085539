import { FC } from 'react';
import { Box } from '@mui/material';
import { Rounds } from './Rounds';
import { EventDataProps, EventDataUpdateRequest } from '../types';

export const TabRounds: FC<EventDataProps> = ({ eventData, isAdmin, session }) => {
    return (
        <div className="TabContent">
            <Box>
                {[...Array(eventData.numberOfRounds || 0)].map((e, roundIndex: number) => {
                    return (
                        <Rounds
                            key={roundIndex}
                            index={roundIndex}
                            eventCode={eventData.eventCode}
                            isAdmin={isAdmin}
                            session={session}
                            teamEvent={eventData.teamEvent}
                        />
                    );
                })}
            </Box>
        </div>
    );
};

export default TabRounds;
