import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import Input from '../input/Input';
import { useMutation, useQuery } from 'react-query';
import { AccountJWT } from '../userContext/UserContextProps';
import { deleteItemApi, updateEventApi } from '../../../network/eventapi';
import FormButton from '../formButton/FormButton';
import { UpdateRoundRequest } from '../../event/tabRounds/types/UpdateRoundRequest';
import { updateRoundApi } from '../../../network/roundsapi';
import { RemoveItem } from '../../event/tabRounds/types/RemoveItem';
import { Typography } from '@mui/material';
import InputSelect from '../inputSelect/InputSelect';
import { genders } from '../../../Data/calendar';
import { getPlayersApi } from '../../../network/playersapi';
import Loading from '../loading/Loading';
import { SelectShow } from '../inputSelect/InputSelectProps';
import { AutocompleteCustom } from '../autocomplete/Autocomplete';
import { getTeamsApi } from '../../../network/teamsapi';

interface EventSettingsPropTypes {
    tableNumber: string;
    roundNumber: string;
    openDialog: boolean;
    eventCode: string;
    handleClose: any;
    session: AccountJWT | null;
    teamEvent: boolean;
}

export const FormDialogTableSettings: FC<EventSettingsPropTypes> = ({
    tableNumber,
    roundNumber,
    eventCode,
    openDialog,
    handleClose,
    session,
    teamEvent,
}) => {
    const [username, setUsername] = useState({ label: '', value: '' });
    const [matchedValue, setMatchedValue] = useState(false);
    const [players, setPlayers] = useState<SelectShow[]>([{ label: '', value: '' }]);
    const [teams, setTeams] = useState<SelectShow[]>([{ label: '', value: '' }]);

    const { mutate: addPlayerToTableRound } = useMutation((variables: UpdateRoundRequest) =>
        updateRoundApi(eventCode, variables, session)
    );
    const { mutate: deleteItem } = useMutation((data: RemoveItem) => deleteItemApi(eventCode, data, session));
    const { data, isLoading } = useQuery([`get-players`, eventCode], () => getPlayersApi(eventCode, session), {
        onSuccess: (data) => {
            setPlayers(
                data.data.map((item: any) => {
                    return { label: item.username, value: item.username };
                })
            );
            console.log(players);
        },
    });
    const { data: teamsData, isLoading: teamsDataLoading } = useQuery(
        [`get-teams`, eventCode],
        () => getTeamsApi(eventCode, session),
        {
            onSuccess: (data) => {
                setTeams(
                    data.data.map((item: any) => {
                        return { label: item.username, value: item.username };
                    })
                );
                console.log(teams);
            },
        }
    );

    // useEffect(() => {
    //     if(data){
    //         players = data?.data.map((item: any) => { return {"label": item.username, "value": item.username}});
    //     }
    // })

    const handleAddPlayer = (e: any) => {
        e.preventDefault();

        let data: UpdateRoundRequest = {
            roundNumber: roundNumber,
            tableNumber: tableNumber,
            items: {},
        };

        data.items[`player#${username.value}`] = {
            isWinner: false,
            username: username.value,
            tableNumber: tableNumber,
            points: 0,
        };
        addPlayerToTableRound(data);
        handleClose();
    };

    const handleDeleteTable = (e: any, tableNumber: string) => {
        e.preventDefault();

        let data = {
            pk: `event#${eventCode}`,
            sk: `round#${roundNumber}#${tableNumber}`,
            roundNumber: roundNumber,
        };

        deleteItem(data);
        handleClose();
    };

    return (
        <>
            {(teamsDataLoading || isLoading) && players ? (
                <Loading isLoading />
            ) : (
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            backgroundColor: '#383838',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                        },
                    }}
                >
                    <DialogTitle
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#FFD15C',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                        }}
                    >
                        TABLE SETTINGS
                    </DialogTitle>
                    <form
                        onSubmit={(e) => {
                            handleDeleteTable(e, tableNumber);
                        }}
                        className="EventSettingsForm"
                    >
                        <FormButton label="Remove Table" style={{ backgroundColor: '#FF5C5C' }} />
                    </form>
                    <Typography
                        style={{
                            textAlign: 'center',
                            color: 'white',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                        }}
                        className="NormalFont"
                    >
                        Or
                    </Typography>
                    <form onSubmit={handleAddPlayer} className="EventSettingsForm">
                        <AutocompleteCustom
                            required
                            label={teamEvent ? 'Team Name' : 'Username'}
                            value={teamEvent ? teams : players}
                            selectedValue={username}
                            setValue={setUsername}
                            style={{ width: '284px', height: '52px' }}
                            setMatchedValue={setMatchedValue}
                        />
                        <FormButton
                            disabled={!matchedValue}
                            label={teamEvent ? '+ Add New Team' : '+ Add New Player'}
                            style={{ marginBottom: '10px' }}
                        />
                    </form>
                </Dialog>
            )}
        </>
    );
};
