import { FC, useEffect, useState } from "react";
import Loading from "../../utils/loading/Loading";
import TabTeamsAdmin from "./TabTeamsAdmin";
import TabTeamsUser from "./TabTeamsUser";
import { useQuery } from "react-query";
import { getTeamsApi } from "../../../network/teamsapi";
import { TeamData } from "./types/TeamData";
import { EventDataProps } from "../types";
import { getPlayersApi } from "../../../network/playersapi";

export const TabTeams: FC<EventDataProps> = ({
  eventData,
  isAdmin,
  session,
}) => {
  const [teams, setTeams] = useState([]);
  const [event] = useState(eventData);

  const eventCode = window.location.pathname.split("/")[2];

  const { data, isLoading, error } = useQuery(
    [`get-teams`, eventCode],
    () => getTeamsApi(eventCode, session),
    {
      enabled: !!session,
    }
  );

  useEffect(() => {
    if (data) {
      setTeams(data.data);
    }
  }, [data]);

  // useEffect(() => {
  //   if (playersData) {
  //     setPlayers(playersData.data);
  //   }
  // }, [playersData]);

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <div className="TabContent">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : isAdmin ? (
        <TabTeamsAdmin
          teamsData={teams as TeamData[]}
          eventData={event}
          session={session}
        />
      ) : (
        <TabTeamsUser
          teamsData={teams as TeamData[]}
          eventData={event}
          session={session}
        />
      )}
    </div>
  );
};

export default TabTeams;
