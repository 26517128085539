import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { TableRow } from './TableRows';
import { FormDialogRoundPlayer, FormDialogTableSettings } from '../../utils/formDialog';
import { AccountJWT } from '../../utils/userContext/UserContextProps';
import { UpdateRoundRequest } from './types/UpdateRoundRequest';
import { PlayerData } from './types/PlayerData';
import { useMutation } from 'react-query';
import { updateRoundApi } from '../../../network/roundsapi';
import { AdminActionButtonSmall } from '../../utils/adminActionButton/AdminActionButtonSmall';
import edit from '../../../Images/settings.png';

interface TablesPropTypes {
    table: { players: []; table: string };
    eventCode: string;
    roundNumber: string;
    isAdmin: boolean;
    teamEvent: boolean;
    session: AccountJWT | null;
    handleDeleteTable: any;
}

export const Table: FC<TablesPropTypes> = ({
    table,
    eventCode,
    roundNumber,
    isAdmin,
    session,
    handleDeleteTable,
    teamEvent,
}) => {
    const [players, setPlayers] = useState<PlayerData[]>([]);
    const [openPlayerDialog, setOpenPlayerDialog] = useState(false);
    const [openTableDialog, setOpenTableDialog] = useState(false);

    const {
        mutate: updateScore,
        isSuccess,
        isLoading,
        isError,
    } = useMutation((data: UpdateRoundRequest) => updateRoundApi(eventCode, data, session));

    useEffect(() => {
        setPlayers(table.players);
    }, [table.players]);

    const handleOpenPlayerDialog = () => {
        setOpenPlayerDialog(true);
    };

    const removePlayerFromTable = (username: string) => {
        let newPlayers = players.filter((el) => el.username !== username);
        setPlayers(newPlayers);

        let variables: UpdateRoundRequest = {
            roundNumber: roundNumber,
            tableNumber: table.table,
            items: {},
        };

        variables.items[`player#${username}`] = null;
        updateScore(variables);
    };

    const updatePlayerIsWinner = (username: string, isWinner: boolean) => {
        let newPlayers = players.map((el) => (el.username === username ? { ...el, isWinner } : el));
        setPlayers(newPlayers);

        let variables: UpdateRoundRequest = {
            roundNumber: roundNumber,
            tableNumber: table.table,
            items: {},
        };

        variables.items[`player#${username}`] = {
            ...newPlayers.find((el) => el.username === username),
            isWinner: isWinner,
            table: table.table,
            round: roundNumber,
            timestamp: new Date().toISOString(),
        };

        updateScore(variables);
    };

    const updatePlayerPoints = (username: string, points: string) => {
        let newPlayers = players.map((el) => (el.username === username ? { ...el, points } : el));
        setPlayers(newPlayers);

        let variables: UpdateRoundRequest = {
            roundNumber: roundNumber,
            tableNumber: table.table,
            items: {},
        };

        variables.items[`player#${username}`] = {
            ...newPlayers.find((el) => el.username === username),
            table: table.table,
            round: roundNumber,
            timestamp: new Date().toISOString(),
        };

        updateScore(variables);
    };

    return (
        <Box>
            <FormDialogRoundPlayer
                tableNumber={table.table}
                roundNumber={roundNumber}
                eventCode={eventCode}
                openDialog={openPlayerDialog}
                handleClose={() => setOpenPlayerDialog(false)}
                session={session}
            />
            <FormDialogTableSettings
                tableNumber={table.table}
                roundNumber={roundNumber}
                teamEvent={teamEvent}
                openDialog={openTableDialog}
                eventCode={eventCode}
                session={session}
                handleClose={() => setOpenTableDialog(false)}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 4,
                }}
            >
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        {table.table?.replace(/[A-Za-z]+/i, 'Table ')}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        Is Winner
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, marginLeft: 1 }}>Points</Typography>
                    {isAdmin && (
                        <a
                            style={{
                                alignItems: 'center',
                                width: '20%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 25,
                                marginRight: 5,
                            }}
                        >
                            <img
                                className="SettingsIcon"
                                src={edit}
                                onClick={() => {
                                    setOpenTableDialog(true);
                                }}
                            />
                        </a>
                    )}
                </Box>
            </Box>
            {players.length > 0 ? (
                players.map((player, index) => {
                    return (
                        <TableRow
                            key={index}
                            playerData={player}
                            eventCode={eventCode}
                            roundNumber={roundNumber}
                            tableNumber={table.table}
                            session={session}
                            isAdmin={isAdmin}
                            removePlayers={removePlayerFromTable}
                            updatePlayerPoints={updatePlayerPoints}
                            updatePlayerIsWinner={updatePlayerIsWinner}
                        />
                    );
                })
            ) : (
                <div className="EmptyList">Table is not populated yet.</div>
            )}
        </Box>
    );
};
