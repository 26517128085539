import { FC, useState } from 'react';
import { Box, Input, Link } from '@mui/material';
import '../../../css/TabPlayers.css';
import { DeleteTeam, TeamsProps, UpsertTeam } from './types/TeamData';
import close from '../../../Images/cross.png';
import { useMutation, useQuery } from 'react-query';
import { confirmTeamsApi, removeTeamsApi, upsertTeamApi } from '../../../network/teamsapi';
import { FormDialogAddPlayer } from '../../utils/formDialog/FormDialogAddPlayer';
import { AdminActionButtonSmall } from '../../utils/adminActionButton/AdminActionButtonSmall';
import Collapsible from 'react-collapsible';
import { AdminSmallButton } from '../../utils/adminSmallButton/AdminSmallButton';
import triangle from '../../../Images/triangle.png';
import Loading from '../../utils/loading/Loading';
import { FormDialogAddTeam } from '../../utils/formDialog/FormDialogAddTeam';
import '../../../css/TabRounds.css';
import { AutocompleteCustom } from '../../utils/autocomplete/Autocomplete';
import FormButton from '../../utils/formButton/FormButton';
import { SelectShow } from '../../utils/inputSelect/InputSelectProps';
import { Height } from '@material-ui/icons';
import { getPlayersApi } from '../../../network/playersapi';

export const TabTeamsAdmin: FC<TeamsProps> = ({ teamsData, eventData, session }) => {
    const eventCode = window.location.pathname.split('/')[2];
    const [openDialog, setOpenDialog] = useState(false);

    const [player, setPlayer] = useState({ label: '', value: '' });
    const [players, setPlayers] = useState([]);
    const [matchedValue, setMatchedValue] = useState(false);
    const { data: playersData, isLoading: playersLoading } = useQuery(
        [`get-players`, eventCode],
        () => getPlayersApi(eventCode, session),
        {
            onSuccess: (data) => {
                setPlayers(
                    data.data.map((item: any) => {
                        return { label: item.username, value: item.username };
                    })
                );
                console.log(players);
            },
        }
    );
    const { mutate: addPlayerToTeam, isLoading: loadingAddPlayerToTeam } = useMutation((variables: UpsertTeam) =>
        upsertTeamApi(eventCode, variables, session)
    );

    const { mutate: updateTeam, isLoading: loadingUpdatePlayer } = useMutation((variables: UpsertTeam) =>
        confirmTeamsApi(eventCode, variables, session)
    );
    const { mutate: deleteTeam, isLoading: loadingDeletePlayer } = useMutation((variables: DeleteTeam) =>
        removeTeamsApi(eventCode, variables, session)
    );

    const handleAddPlayer = (e: any, username: string, players: string[]) => {
        e.preventDefault();

        console.log('Team is' + username);
        console.log(players);

        console.log(`Adding username ${player.value}`);

        let data: UpsertTeam = {
            username: username,
            players: [...players, player.value],
        };

        addPlayerToTeam(data);

        setPlayer({ label: '', value: '' });
    };

    const removePlayerFromTeam = (e: any, username: string, player: string, players: string[]) => {
        e.preventDefault();

        console.log('Player is ' + player);
        console.log(`Players list is: ${players}`);

        let indexOfPlayer = (players as string[]).indexOf(player);
        console.log(`Index of player is: ${indexOfPlayer}`);

        players.splice(indexOfPlayer, 1);

        console.log(`Players list after removing is: ${players}`);

        let data: UpsertTeam = {
            username: username,
            players: players,
        };

        addPlayerToTeam(data);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (loadingDeletePlayer || loadingUpdatePlayer) {
        return <Loading isLoading={loadingDeletePlayer || loadingUpdatePlayer} />;
    }

    return (
        <>
            <FormDialogAddTeam
                eventCode={eventCode}
                openDialog={openDialog}
                handleClose={handleCloseDialog}
                session={session}
            />
            <AdminActionButtonSmall label="Add Team" handleGenerate={handleOpenDialog} styles={{ marginBottom: 1 }} />
            {teamsData.map((row) => (
                <Collapsible
                    key={row.username}
                    classParentString="PlayersInfoCollapsible"
                    trigger={
                        <div key={row.username} className="TableTeamsRow">
                            <div className="TableCell">{row.username}</div>
                            <div className="TableCell">Members: {row.players.length}</div>
                            <a className="ArrowButton">
                                <img className="ProfileArrow" src={triangle} />
                            </a>
                        </div>
                    }
                >
                    <div className="TeamInfo">
                        {loadingAddPlayerToTeam ? (
                            <Loading isLoading={loadingAddPlayerToTeam} />
                        ) : (
                            row.players.map((player: string) => (
                                <div key={player} className="TableTeamsRow">
                                    <div className="TableCell">{player}</div>
                                    <Link onClick={(e) => removePlayerFromTeam(e, row.username, player, row.players)}>
                                        <Box
                                            sx={{
                                                width: '30px',
                                                height: '30px',
                                                backgroundColor: 'transparent',
                                                display: 'center',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginLeft: 3,
                                            }}
                                        >
                                            <img className="NavbarCross" src={close} alt="Logo" />
                                        </Box>
                                    </Link>
                                    {/* <AdminSmallButton
                  label="Remove"
                  handleGenerate={() => removeTeam(row.username)}
                /> */}
                                </div>
                            ))
                        )}
                        <form
                            onSubmit={(e) => handleAddPlayer(e, row.username, row.players)}
                            className="AddPlayerToTeamForm"
                        >
                            <AutocompleteCustom
                                required
                                label="Username"
                                value={players}
                                selectedValue={player}
                                setValue={setPlayer}
                                style={{ width: '200px', height: '22px' }}
                                setMatchedValue={setMatchedValue}
                            />
                            <FormButton
                                disabled={!matchedValue}
                                label="+ Add"
                                style={{ width: '84px', height: '52px' }}
                            />
                        </form>
                        <AdminSmallButton
                            label="Remove"
                            handleGenerate={() => deleteTeam({ username: row.username })}
                        />
                    </div>
                </Collapsible>
            ))}
        </>
    );
};

export default TabTeamsAdmin;
