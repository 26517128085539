import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generateLeaderboardApi, getLeaderboardApi } from '../../../network/leaderboardapi';
import Loading from '../../utils/loading/Loading';
import { LeaderboardData } from './types/LeaderboardData';
import { EventDataProps } from '../types';
import { AdminActionButtonSmall } from '../../utils/adminActionButton/AdminActionButtonSmall';
import { EventSystem } from '../enums';

export const Leaderboard: FC<EventDataProps> = ({ eventData, isAdmin, session }) => {
    const [leaderboard, setLeaderboard] = useState<LeaderboardData[]>([]);
    const [csvData, setCsvData] = useState<any>([]);
    const eventCode = window.location.pathname.split('/')[2];

    const { data, isLoading, error, refetch } = useQuery(['get-leaderboard', eventData.eventCode], () =>
        getLeaderboardApi(eventData.eventCode, session)
    );

    // const { data: generateLeaderboardData, isLoading: generateLoading, refetch: generateLeaderboard } = useQuery("generate-leaderboard", () => generateLeaderboardApi(eventCode), { enabled: false });
    const { mutate: generateLeaderboard, isLoading: generateLoading } = useMutation(
        () => generateLeaderboardApi(eventCode, session),
        {
            onSuccess: (response: { data: LeaderboardData[] }) => {
                setLeaderboard(response.data);
            },
        }
    );

    useEffect(() => {
        if (data) {
            console.log(eventData);
            const leaderboard = data.data;
            setLeaderboard(leaderboard);

            const rowKeys: Set<string> = new Set(
                ...data.data.map((row: { [key: string]: any }) => {
                    return Object.keys(row);
                })
            );

            let rowKeysRoundsProps: string[] = [];
            for (const prop of [...rowKeys]) {
                if (/-points$/.test(prop) || /-competitionPoints$/.test(prop) || /-tablePercentage$/.test(prop)) {
                    rowKeysRoundsProps.push(prop);
                }
            }

            rowKeysRoundsProps.sort();

            const leaderboardCsv = [
                [
                    'Place',
                    'Name',
                    ...rowKeysRoundsProps.map((elem) => {
                        const rearrange = elem.split('-');
                        let collumnName;

                        switch (rearrange[1]) {
                            case 'points':
                                collumnName = 'Points';
                                break;
                            case 'competitionPoints':
                                collumnName = 'Competition Points';
                                break;
                            case 'tablePercentage':
                                collumnName = 'Table %';
                                break;
                        }
                        return `${rearrange[0].charAt(0).toUpperCase()}${rearrange[0].charAt(5)} ${collumnName}`;
                    }),
                    'Competition Points Total',
                    'Points Total',
                    '% Total',
                ],
                ...data.data.map(
                    (
                        row: {
                            [key: string]: any;
                        },
                        index: number
                    ) => {
                        const arr = [index + 1, row.username];
                        for (const prop of rowKeysRoundsProps) {
                            arr.push(row[`${prop}`]);
                        }

                        arr.push(...[row.specialPoints, row.points, row.percentage]);
                        return arr;
                    }
                ),
            ];
            setCsvData(leaderboardCsv);
        }
    }, [data]);

    const exportDataAsCsv = () => {
        let csvContent = 'data:text/csv;charset=utf-8,' + csvData.map((e: any[]) => e.join(',')).join('\n');
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${eventCode}-leaderboard.csv`);
        document.body.appendChild(link);

        link.click();
    };

    const handleGenerate = () => {
        generateLeaderboard();
    };

    const LeaderboardTable = () => {
        if (isLoading || generateLoading) {
            return <Loading isLoading={isLoading} />;
        }
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontSize: '10px' }}>Pos.</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: '10px' }}>Name</TableCell>
                            {(eventData.eventSystem === EventSystem.CatanOfficialTournament ||
                                eventData.eventSystem === EventSystem.CarcassonneOfficialTournament ||
                                eventData.eventSystem === undefined) && (
                                <TableCell sx={{ color: 'white', fontSize: '10px' }}>S.P</TableCell>
                            )}
                            {eventData.eventSystem === EventSystem.Generic && (
                                <TableCell sx={{ color: 'white', fontSize: '10px' }}>Wins</TableCell>
                            )}
                            <TableCell sx={{ color: 'white', fontSize: '10px' }}>P</TableCell>
                            {(eventData.eventSystem === EventSystem.CatanOfficialTournament ||
                                eventData.eventSystem === EventSystem.CarcassonneOfficialTournament ||
                                eventData.eventSystem === undefined) &&
                                leaderboard.some((elem) => elem.percentage != null) && (
                                    <TableCell sx={{ color: 'white', fontSize: '10px' }}>%</TableCell>
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaderboard.map(
                            (
                                row: {
                                    username: string;
                                    specialPoints: number;
                                    points: number;
                                    percentage: number;
                                    isWinnerCount: number;
                                },
                                index: number
                            ) => {
                                return (
                                    <TableRow key={index} sx={{ '& td': { border: 0 } }}>
                                        <TableCell sx={{ color: 'white', fontSize: '10px' }}>{index + 1}</TableCell>
                                        <TableCell sx={{ color: 'white', fontSize: '10px' }}>{row.username}</TableCell>
                                        {(eventData.eventSystem === EventSystem.CatanOfficialTournament ||
                                            eventData.eventSystem === EventSystem.CarcassonneOfficialTournament ||
                                            eventData.eventSystem === undefined) && (
                                            <TableCell
                                                sx={{
                                                    color: 'white',
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {row.specialPoints
                                                    ? Number.isInteger(row.specialPoints)
                                                        ? row.specialPoints
                                                        : row.specialPoints?.toFixed(2)
                                                    : 0}
                                            </TableCell>
                                        )}
                                        {eventData.eventSystem === EventSystem.Generic && (
                                            <TableCell
                                                sx={{
                                                    color: 'white',
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {row.isWinnerCount ? row.isWinnerCount : 0}
                                            </TableCell>
                                        )}
                                        <TableCell sx={{ color: 'white', fontSize: '10px' }}>{row.points}</TableCell>
                                        {(eventData.eventSystem === EventSystem.CatanOfficialTournament ||
                                            eventData.eventSystem === EventSystem.CarcassonneOfficialTournament ||
                                            eventData.eventSystem === undefined) &&
                                            row.percentage != null && (
                                                <TableCell sx={{ color: 'white', fontSize: '10px' }}>
                                                    {row.percentage
                                                        ? Number.isInteger(row.percentage)
                                                            ? row.percentage
                                                            : row.percentage?.toFixed(2)
                                                        : 0}
                                                </TableCell>
                                            )}
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    return (
        <div className="TabContent">
            {isAdmin && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <AdminActionButtonSmall
                        label="Generate leaderboard"
                        handleGenerate={handleGenerate}
                        styles={{ width: '48%' }}
                    />
                    <AdminActionButtonSmall
                        label="Export to CSV"
                        handleGenerate={exportDataAsCsv}
                        styles={{ width: '48%' }}
                    />
                </div>
            )}
            <LeaderboardTable />
        </div>
    );
};
