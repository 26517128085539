import { FC, useState } from "react";
import "../../../css/TabPlayers.css";
import { DeletePlayer, PlayersProps, UpdatePlayer } from "./types/PlayerData";
import close from "../../../Images/cross.png";
import { useMutation } from "react-query";
import { confirmPlayersApi, removePlayersApi } from "../../../network/playersapi";
import { FormDialogAddPlayer } from "../../utils/formDialog/FormDialogAddPlayer";
import { AdminActionButtonSmall } from "../../utils/adminActionButton/AdminActionButtonSmall";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import { Button } from "react-bootstrap";
import { AdminSmallButton } from "../../utils/adminSmallButton/AdminSmallButton";
import triangle from "../../../Images/triangle.png";
import Loading from "../../utils/loading/Loading";

export const TabPlayersAdmin: FC<PlayersProps> = ({ playersData, session }) => {
    const eventCode = window.location.pathname.split("/")[2];
    const [openDialog, setOpenDialog] = useState(false);

    const { mutate: updatePlayer, isLoading: loadingUpdatePlayer } = useMutation((variables: UpdatePlayer) => confirmPlayersApi(eventCode, variables, session));
    const { mutate: deletePlayer, isLoading: loadingDeletePlayer } = useMutation((variables: DeletePlayer) => removePlayersApi(eventCode, variables, session));

    const confirmPlayer = (email: string) => {
        const data = {
            items: { isConfirmed: true },
            player: email,
        };

        updatePlayer(data);
    };

    const removePlayer = (username: string) => {
        const data = {
            username: username,
        };

        deletePlayer(data);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if(loadingDeletePlayer || loadingUpdatePlayer) {
        return <Loading isLoading={loadingDeletePlayer || loadingUpdatePlayer}/>
    }

    return (
        <>
            <FormDialogAddPlayer eventCode={eventCode} openDialog={openDialog} handleClose={handleCloseDialog} session={session} />
            <AdminActionButtonSmall label="Add Player" handleGenerate={handleOpenDialog} styles={{ marginBottom: 1 }} />
            {playersData.map((row) =>
                row.isConfirmed ? (
                    <Collapsible
                        key={row.username}
                        classParentString="PlayersInfoCollapsible"
                        trigger={
                            <div key={row.username} className="TablePlayersRow">
                                <Link to={`/users/${row.username}`} className="TableCell">
                                    {row.username}
                                </Link>
                                <div className="JoinedCell">JOINED</div>
                                <a className="ArrowButton">
                                    <img className="ProfileArrow" src={triangle} />
                                </a>
                            </div>
                        }
                    >
                        <div className="PlayerInfo">
                            <div>
                                <div>Email: {row.userEmail ? row.userEmail : "N/A"}</div>
                                <div>Phone: {row.phone ? row.phone : "N/A"}</div>
                            </div>
                            <AdminSmallButton label="Remove" handleGenerate={() => removePlayer(row.username)} />
                        </div>
                    </Collapsible>
                ) : (
                    <Collapsible
                        key={row.username}
                        classParentString="PlayersInfoCollapsible"
                        trigger={
                            <div key={row.username} className="TablePlayersRow">
                                <Link to={`/users/${row.username}`} className="TableCell">
                                    {row.username}
                                </Link>
                                <div className="RowButtons">
                                    <button className="RowButton RowButtonAccept NormalFont" onClick={() => confirmPlayer(row.username)}>
                                        ACCEPT
                                    </button>
                                    <button className="RowButton RowButtonDecline NormalFont" onClick={() => removePlayer(row.username)}>
                                        DECLINE
                                    </button>
                                </div>
                                <a className="ArrowButton">
                                    <img className="ProfileArrow" src={triangle} />
                                </a>
                            </div>
                        }
                    >
                        <div className="PlayerInfo">
                            <div>
                                <div>Email: {row.userEmail ? row.userEmail : "N/A"}</div>
                                <div>Phone: {row.phone ? row.phone : "N/A"}</div>
                            </div>
                        </div>
                    </Collapsible>
                )
            )}
        </>
    );
};

export default TabPlayersAdmin;
