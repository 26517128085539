import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useEffect, useState } from "react";
import Input from "../input/Input";
import FormDialogButton from "./FormDialogButton";
import { useMutation } from "react-query";
import { AccountJWT } from "../userContext/UserContextProps";
import { updateEventApi } from "../../../network/eventapi";
import SwitchToggle from "../switch/SwitchToggle";
import { EventDataResponse, EventDataUpdateRequest } from "../../event/types";
import FormButton from "../formButton/FormButton";
import { eventSystems } from "../../../Data/eventsData";
import InputSelect from "../inputSelect/InputSelect";

interface EventSettingsPropTypes {
    openDialog: boolean;
    eventData: EventDataResponse;
    setEventData: Function;
    handleClose: any;
    session: AccountJWT | null;
}

export const FormDialogEventSettings: FC<EventSettingsPropTypes> = ({ openDialog, eventData, handleClose, session }) => {
    const { mutate: udpateEvent } = useMutation((data: EventDataUpdateRequest) => updateEventApi(eventData.eventCode, data, session));
    const [location, setLocation] = useState(eventData.location);
    const [eventGame, setEventGame] = useState(eventData?.eventName?.replace(`@${eventData.location}`, ""));
    const [rounds, setRounds] = useState(eventData.numberOfRounds);
    const [year, setYear] = useState(eventData?.eventDate?.substring(0, 4));
    const [month, setMonth] = useState(eventData?.eventDate?.substring(5, 7));
    const [day, setDay] = useState(eventData?.eventDate?.substring(8, 10));
    const [pairWinners, setPairWinners] = useState(eventData.pairWinners);
    const [submitCheck, setSubmitCheck] = useState(false);
    const [birthdateError, setBirthdateError] = useState(false);
    const [onlyOrganizerAccess, setOnlyOrganizerAccess] = useState(eventData.onlyOrganizerAccess);
    const [eventSystem, setEventSystem] = useState(eventData.eventSystem);
    const [teamEvent, setTeamEvent] = useState(eventData.teamEvent);

    const handleEditProfile = (e: any) => {
        e.preventDefault();

        const data: EventDataUpdateRequest = {
            items: {
                numberOfRounds: typeof rounds === "string" ? Number.parseInt(rounds) : rounds,
                eventDate: parseDate(day, month, year),
                pairWinners,
                location,
                eventName: `${eventGame}@${location}`,
                onlyOrganizerAccess,
                eventSystem,
                teamEvent
            },
        };

        udpateEvent(data);
        handleClose();
    };

    const parseDate = (day: string, month: string, year: string): string => {
        let transformedDate = new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day), 20, 0);
        return transformedDate.toISOString();
    }

    useEffect(() => {
        if (day?.length != 2 && day?.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [day]);

    useEffect(() => {
        if (month?.length != 2 && month?.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [month]);

    useEffect(() => {
        if (year?.length != 4 && year?.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [year]);

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#383838", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" } }}>
                <DialogTitle style={{ display: "flex", justifyContent: "center", color: "#FFD15C", fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "16px" }}>
                    EVENT SETTINGS
                </DialogTitle>
                <form onSubmit={handleEditProfile} className="EventSettingsForm">
                    <div className="EventSettingsDate">
                        <Input
                            label="DD"
                            setValue={setDay}
                            defaultValue={day}
                            error={birthdateError}
                            required
                            style={{
                                marginRight: "15px",
                            }}
                        />
                        <Input
                            label="MM"
                            setValue={setMonth}
                            defaultValue={month}
                            error={birthdateError}
                            required
                            style={{
                                marginRight: "15px",
                            }}
                        />
                        <Input label="YYYY" setValue={setYear} defaultValue={year} error={birthdateError} required />
                    </div>
                    <Input
                        label="Location"
                        setValue={setLocation}
                        defaultValue={location}
                        required
                        style={{
                            marginBottom: "20px",
                            width: "100%",
                        }}
                    />
                    <Input
                        label="Event Game"
                        setValue={setEventGame}
                        defaultValue={eventGame}
                        required
                        style={{
                            marginBottom: "20px",
                        }}
                    />
                    <InputSelect value={eventSystems} selectedValue={eventSystem} required label="Event System" setValue={setEventSystem} style={{ width: "284px", height: "52px", marginBottom: "20px" }} />
                    <Input
                        label="No Of Rounds"
                        setValue={setRounds}
                        defaultValue={rounds?.toString()}
                        type="number"
                        required
                        style={{
                            marginBottom: "20px",
                        }}
                    />
                    <SwitchToggle label="Pair Winners" setToggle={setPairWinners} checked={pairWinners} />
                    <SwitchToggle label="Only Organizer Access" setToggle={setOnlyOrganizerAccess} checked={onlyOrganizerAccess}/>
                    <SwitchToggle label="Team Event" setToggle={setTeamEvent} checked={teamEvent}/>
                    <FormButton label="Save" disabled={submitCheck} />
                </form>
            </Dialog>
        </>
    );
};
