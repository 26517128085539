import { Box, CircularProgress } from "@mui/material";
import { LoadingProps } from "./LoadingProps";

function Loading({ isLoading, style }: LoadingProps) {
    let defaultStyles = { justifyContent: "center", display: "flex", marginTop: 4 };

    if (style) {
        Object.assign(defaultStyles, style);
    }

    return (
        <Box sx={defaultStyles}>
            <CircularProgress sx={{ color: "#FFD15C" }} />
        </Box>
    );
}

export default Loading;
