import { RemoveItem } from "../Components/event/tabRounds/types/RemoveItem";
import { EventDataRequest, EventDataResponse, EventDataUpdateRequest } from "../Components/event/types";
import { AccountJWT } from "../Components/utils/userContext/UserContextProps";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;
export const WS_URL = process.env.REACT_APP_WS_URL;

export const getEventApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.get(BASE_URL + `/events/${eventCode}`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const joinEventApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.post(BASE_URL + `/events/${eventCode}`, {}, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
}

export const createEventApi = async (data: EventDataRequest, session: AccountJWT | null): Promise<{ data: EventDataResponse }> => {
    return axios.post(BASE_URL + `/events`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const updateEventApi = (eventCode: string, data: EventDataUpdateRequest, session: AccountJWT | null) => {
    return axios.patch(BASE_URL + `/events/${eventCode}`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const deleteItemApi = (eventCode: string, data: RemoveItem, session: AccountJWT | null) => {
    return axios.delete(BASE_URL + `/events/${eventCode}`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
        data,
    });
};