import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import Input from "../input/Input";
import FormDialogButton from "./FormDialogButton";
import { useMutation } from "react-query";
import { updateUserApi } from "../../../network/profileapi";
import { AccountJWT } from "../userContext/UserContextProps";
import { UpdateUser } from "../../profile/types/UpdateUser";

interface EditProfilePropTypes {
    username: string;
    description: string;
    setDescription: Function;
    openDialog: boolean;
    handleClose: any;
    session: AccountJWT | null;
}

export const FormDialogEditProfile: FC<EditProfilePropTypes> = ({ description, setDescription, openDialog, handleClose, username, session }) => {
    const { mutate: udpateProfile } = useMutation((data: UpdateUser) => updateUserApi(username, data, session));

    const handleEditProfile = (e: any) => {
        e.preventDefault();

        const data = {
            items: {
                description: description
            }
        }

        udpateProfile(data);
        handleClose();
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#383838", width: "100%"  } }}>
                <DialogTitle style={{ display: "flex", justifyContent: "center", color: "#FFD15C", fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "16px" }}>
                    Edit Profile
                </DialogTitle>
                <form onSubmit={handleEditProfile}>
                    <DialogContent style={{ display: "flex", justifyContent: "center", paddingTop: "10px", height: "100px" }}>
                        <Input label="Description" value={description} setValue={setDescription} multiline={true} rows={3} style={{color: "#FFFFF"}}/>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                        <FormDialogButton label="Save" />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
