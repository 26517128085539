import { FormControlLabel, styled, Switch } from "@mui/material";
import { SwitchProps } from "./SwitchToggleProps";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 21,
  padding: 0,
  borderRadius: "20px",
  display: "flex",  
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20,
      backgroundColor: "#FFD15C",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(20px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      marginRight: "0px",
      transform: "translateX(23px)",
      color: "#FFD15C",
      "& + .MuiSwitch-track": {
        //track color
        opacity: 1,
        backgroundColor: "rgba(0,0,0,.25)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 17,
    height: 17,
    borderRadius: 11,
    marginRight: "0px",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function SwitchToggle({ label, setToggle, checked=false }: SwitchProps) {
  return (
    <div className="EventOption">
      <div className="EventOptionLabel">{label}</div>
      <div className="EventOptionValue" >
        <FormControlLabel
          control={
            <CustomSwitch checked={checked} onChange={(e) => setToggle(e.target.checked)} />
          }
          label=""
          style={
            {
              marginRight: "0px",
            }
          }
        />
      </div>
    </div>
  );
}

export default SwitchToggle;
