import { Button } from "@mui/material";
import { FormButtonProps } from "./FormButtonProps";


function FormButton({ label, disabled, style }: FormButtonProps) {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      style={style}
      sx={{
        backgroundColor: "#FFD15C",
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "12px",
        color: "black",
        width: "284px",
        height: "52px",
        "&:hover": {
          backgroundColor: "#FFD15C",
          color: "#262626",
        },
      }}
      type="submit"
    >
      {label}
    </Button>
  );
}

export default FormButton;
