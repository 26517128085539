import { AccountJWT } from "../Components/utils/userContext/UserContextProps";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

export const getLeaderboardApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.get(BASE_URL + `/events/${eventCode}/leaderboard`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const generateLeaderboardApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.post(
        BASE_URL + `/events/${eventCode}/leaderboard`,
        {},
        {
            headers: {
                Authorization: session?.idToken.getJwtToken(),
            },
        }
    );
};