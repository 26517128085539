import { useContext, useEffect, useState } from "react";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "../../css/LoginPage.css";
import logo from "../../Images/boardgame.png";
import Input from "../utils/input/Input";
import InputPassword from "../utils/inputPassword/InputPassword";
import FormButton from "../utils/formButton/FormButton";
import { AccountContext } from "../utils/userContext/UserContext";
import PageFooter from "../utils/footer/PageFooter";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [wrongPasswordError, setWrongPasswordError] = useState(false);
    const [notConfirmedAccountError, setNotConfirmedAccountError] = useState(false);
    const { authenticate } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        setWrongPasswordError(false);
        setNotConfirmedAccountError(false);
      }, [username, password])

    const onSubmit = (e: any) => {
        e.preventDefault();
        authenticate(username, password)
            .then((data: any) => {
                navigate("/events");
            })
            .catch((err: any) => {
                if(err.toString().includes("UserNotConfirmedException")) {
                    setNotConfirmedAccountError(true);
                } else {
                    setWrongPasswordError(true);
                }
            });
    };

    return (
        <div className="LoginPage">
            <div className="LogoImage MarginTopLogin">
                <a href="/events"><img src={logo} alt="Logo" /></a>
            </div>

            <div className="LoginPageForm">
                <div className="IntroLogin">
                    <div className="WelcomeBackDiv">Welcome Back</div>
                    <div style={{ fontSize: "20px", marginBottom: "43px" }}>Log into your account</div>
                </div>

                <form onSubmit={onSubmit} className="FormCss">
                    <Input required label="Email or Username" setValue={setUsername} error={wrongPasswordError} />
                    <InputPassword required label="Password" setValue={setPassword} error={wrongPasswordError} />
                    {wrongPasswordError && <div className="ErrorStyle">Incorrect username or password</div>}
                    {notConfirmedAccountError && <div className="ErrorStyle">Please check your email to verify account.</div>}
                    <FormButton label="Login" />
                </form>

                <Link href="/forgotpassword" className="ForgotPasswordLink">
                    forgot password?
                </Link>
                <div className="DontHaveAnAccount">
                    Don't have an account?{" "}
                    <Link href="/register" style={{ color: "#FFD15C", textDecoration: "none" }}>
                        Register
                    </Link>
                </div>
            </div>

            <PageFooter/>
        </div>
    );
}

export default Login;
