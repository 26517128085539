import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DialogMenuProps } from "./DialogMenuProps";

function DialogMenu({ path, isOpen, setOpen, description }: DialogMenuProps) {
  const navigate = useNavigate();
  const handleClose = () => {
    if (path) navigate(path);
    else setOpen((prev: boolean) => !prev);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ backgroundColor: "#262626" }}>
        <DialogContentText
          id="alert-dialog-description"
          className="merge"
          style={{
            display: "flex",
            fontSize: "20px",
            fontFamily: "Inter",
            fontStyle: "normal",
            color: "white",
          }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#262626" }}>
        <Button
          sx={{
            color: "white",
            fontSize: "15px",
            fontFamily: '"Inter", sans-serif',
            fontStyle: "normal",
          }}
          onClick={handleClose}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogMenu;
