import { Button } from "@mui/material";
import { FormButtonProps } from "../formButton/FormButtonProps";


function FormDialogButton({ label, disabled, style }: FormButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      style={style}
      sx={{
        backgroundColor: "#FFD15C",
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "12px",
        color: "black",
        width: "84px",
        height: "42px",
        "&:hover": {
          backgroundColor: "#FFD15C",
          color: "#262626",
        },
      }}
      type="submit"
    >
      {label}
    </Button>
  );
}

export default FormDialogButton;
