import { Button } from "@mui/material";
import { AdminButtonProps } from "./AdminButtonProps";

export function AdminActionButtonSmall({label, handleGenerate, styles}:AdminButtonProps) {
    let defaultStyles = {
        borderColor: "#FFD15C",
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "12px",
        color: "#FFD15C",
        width: "100%",
        height: "40px",
        borderRadius: "0px",
        "&:hover": {
            backgroundColor: "#FFD15C",
            borderColor: "#FFD15C",
            color: "#262626",
        },
    }

    if(styles) {
        Object.assign(defaultStyles, styles);
    }
    return (
        <Button
            sx={defaultStyles}
            onClick={handleGenerate}
            variant="outlined"
        >
            {label}
        </Button>
    );
}
