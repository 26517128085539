import logo from "../Images/boardgame.png";
import PageFooter from "./utils/footer/PageFooter";

export const PrivacyPolicy = () => {
    return (
        <div className="LoginPage">
        <div className="LogoImage MarginTop50">
            <a href="/events">
                <img src={logo} alt="Logo" />
            </a>
        </div>
            <div className="EventsPageWeb NormalFont">
                <div className="TermsAndConditions">
                    <h1>Privacy Policy</h1>
                    <div className="TermsAndConditionsContent">
                        <p>
                            We appreciate your interest in our Privacy Policy. At Board Games Events, we take the protection of your data very seriously, and in the below we explain what data we
                            collect when you use our website and web application (collectively “website”) and how it is used.
                        </p>
                        <p>
                            <strong></strong>
                        </p>
                        <p>
                            <strong>General Information</strong>
                        </p>
                        <p>
                            <em>What law applies?</em>
                        </p>
                        <p>
                            Our use of your Personal Data is subject to both Romania`s Law no. 190/2018 (“Law no. 190”) and the EU's General Data Protection Regulation (“GDPR”), and of course we
                            process your Personal Data accordingly.
                        </p>
                        <p>
                            <em>What is Personal Data?</em>
                        </p>
                        <p>
                            Personal Data is any information relating to personal or material circumstances that relates to an individual. This may include, a name, date of birth, e-mail address,
                            postal address, or telephone number but also online identifiers such as IP addresses or device ID`s.
                        </p>
                        <p>
                            <em>What is processing?</em>
                        </p>
                        <p>
                            "Processing" means any operation or set of operations which is performed upon Personal Data, whether or not by automatic means. The term is broad and covers virtually any
                            handling of data.
                        </p>
                        <p>
                            <em>Who is responsible for data processing?</em>
                        </p>
                        <p>
                            The responsible party within the meaning of Law no. 190 and the GDPR is Board Games Events, Lupusoru Alexandru Intreprindere Individuala, of Sat Dobrovăț, Comuna Dobrovăț,
                            DJ. 247, NR. CADASTRAL 62844-C, Judet lasi, Romania (“Board Games Events”, “we”, “us”, or “our”).
                        </p>
                        <p>If you have any questions or if you wish to exercise your rights, please email boardgamesevents@gmail.com, call +40 788 223 909, or write to us at the above address.</p>
                        <p>
                            <em>What are the legal bases of processing?</em>
                        </p>
                        <p>In accordance with Law no. 190 and the GDPR, we have to have at least one of the following legal bases to process your Personal Data:</p>
                        <p>
                            ● <em>For the fulfillment of contractual obligations</em>
                        </p>
                        <p>o The purposes of the data processing are primarily based on the service we provide.</p>
                        <p>
                            ● <em>Within the framework of our legitimate interests</em>
                        </p>
                        <p>Where necessary, we process your data beyond the actual performance of the contract in order to safeguard legitimate interests of us or third parties. Examples are:</p>
                        <p>o Ensuring IT security and IT operations,</p>
                        <p>o Measures for business management and further development of our services,</p>
                        <p>o Defense against third-party claims and enforcement of own claims.</p>
                        <p>
                            ● <em>Based on your consent</em>
                        </p>
                        <p>o Insofar as you have given us your consent to process personal data for certain purposes.</p>
                        <p>
                            <strong>Data we collect automatically</strong>
                        </p>
                        <p>
                            <em>Log data</em>
                        </p>
                        <p>
                            Each time you visit our website, our system automatically records data from the visiting device and stores it in a so-called log file. Among other purely technical
                            information, this includes both device information and the IP address (identification of the user's device). The legal basis for processing is our legitimate interest.
                        </p>
                        <p>
                            <em>Content Delivery Network</em>
                        </p>
                        <p>
                            We use the Content Delivery Network (CDN) of
                            <a href="https://aws.amazon.com/privacy/">Amazon Web Services (AWS)</a>
                            to distribute our online content. A CDN is a network of regionally distributed servers of our technical service providers connected via the Internet. When our website is
                            visited, your device`s browser transmits information to these service providers, which is collected in corresponding server log files. Server log files are generally
                            anonymised and then transmitted without any personal reference. Server log files however include, besides technical information, device information and the IP address of
                            the visiting device. The legal basis for processing is our legitimate interest.
                        </p>
                        <p>
                            <em>Hosting</em>
                        </p>
                        <p>
                            To provide our website, we use the services of
                            <a href="https://aws.amazon.com/privacy/">Amazon Web Services (AWS)</a>, who process all data to be processed in connection with the operation of our website on our behalf.
                            The legal basis for the data processing is our legitimate interest in providing our website.
                        </p>
                        <p>
                            <strong>Data we collect directly</strong>
                        </p>
                        <p>
                            <em>Contacting us</em>
                        </p>
                        <p>
                            In addition to your name, company name, and e-mail address, IP address or telephone number, if provided, we usually collect the context of your message which may also
                            include certain Personal Data. The Personal Data collected when contacting us is to handle your request and the legal basis is both your consent and contract.
                        </p>
                        <p>
                            We are present on social media. If you contact or connect with us via social media platforms, we and the relevant social media platform are jointly responsible for the
                            processing of your data and enter into a so-called joint controller agreement. The legal basis is our legitimate interest, your consent or, in some cases, the initiation of
                            a contract.
                        </p>
                        <p>
                            <em>Registration</em>
                        </p>
                        <p>
                            As part of the registration process, users provide their a) Username; b) E-mail; c) Password. The data provided will be used for the purposes of creating and using the
                            account (admins and event participants) and providing and/or using our services. In the context of the use of our registration and login functions as well as the use of the
                            user account, we store the IP address and the time of the respective user action. The storage is based on our legitimate interests, as well as the user's protection against
                            misuse and other unauthorized use. The legal basis for the data processing is the fulfillment of our contractual obligations and, in individual cases, the fulfillment of
                            our legal obligations as well as consent.
                        </p>
                        <p>
                            <em>Profile</em>
                        </p>
                        <p>
                            As a registered user, you have the opportunity to create a user profile with just a few clicks and details. If you make use of the option, the relevant profile data you
                            provide will be transferred to your profile. Of course, you can change the information at any time via the settings in your profile. The legal basis for the processing of
                            your Personal Data is the establishment and implementation of the user contract for the use of the service. We store the data until you delete your user account.
                        </p>
                        <p>
                            <em>When using our event services (players, rounds and leaderboard)</em>
                        </p>
                        <p>
                            We process the data of our registered users in connection with a specific event, in order to be able to provide our services as well as to ensure the security of our
                            services and to be able to develop it further. For added security, you must have a valid event code to see and access our event services and data made viewable within those
                            services are limited to usernames, scores and other event relevant details. Further, we may also allow Admins to display the event location such as the cafe`s address,
                            which may in turn lead to usernames being capable of being associated with a specific location.
                        </p>
                        <p>
                            The legal basis for the processing of your Personal Data is the establishment and implementation of the user contract for the use of the service as well as your consent.
                            You may withdraw your consent and request us to stop using and/or disclosing your Personal Data by submitting your request to us.
                        </p>
                        <p>
                            The legal basis for the data processing is the fulfillment of our contractual obligations and, in individual cases, the fulfillment of our legal obligations as well as your
                            consent.
                        </p>
                        <p>
                            <em>Administration, financial accounting, office organization, contact management</em>
                        </p>
                        <p>
                            We process data in the context of administrative tasks as well as organization of our business, and compliance with legal obligations, such as archiving. In this regard, we
                            process the same data that we process in the course of providing our contractual services. The processing bases are our legal obligations and our legitimate interest.
                        </p>
                        <p>
                            <em></em>
                        </p>
                        <p>
                            <strong>General Principles</strong>
                        </p>
                        <p>
                            <em>Who receives my data?</em>
                        </p>
                        <p>Within Board Games Events, those that need your data to fulfill our contractual and legal obligations will receive access to it.</p>
                        <p>
                            Processors used by us may also receive data for these purposes. These are companies in the categories of IT services, telecommunications, and sales and marketing. Where we
                            use processors to provide our services, we take appropriate legal precautions and corresponding technical and organizational measures to ensure the protection of personal
                            data in accordance with the relevant legal provisions.
                        </p>
                        <p>
                            Data is only passed on to third parties within the framework of legal requirements. We only pass on users' data to third parties if this is necessary, for example, for
                            contractual purposes or on the basis of legitimate interests in the economic and effective operation of our business or you have consented to the transfer of data.
                        </p>
                        <p>
                            <em>How long will my data be stored?</em>
                        </p>
                        <p>
                            As far as necessary, we process and store your personal data for the duration of our business relationship, which also includes, for example, the initiation and execution
                            of a contract.
                        </p>
                        <p>
                            In addition, we are subject to various storage and documentation obligations, which result from the minimum statutory retention periods. The retention and documentation
                            periods specified there are 2 to 8 years.
                        </p>
                        <p>
                            <em>How do we secure your data?</em>
                        </p>
                        <p>
                            Our website uses SSL or TLS encryption to ensure the security of data processing and to protect the transmission of content or contact requests that you send to us. We have
                            also implemented numerous security measures (“technical and organizational measures”) for example encryption or need to know access, to ensure the most complete protection
                            of Personal Data processed through this website.
                        </p>
                        <p>
                            Nevertheless, internet-based data transmissions can always have security gaps, so that absolute protection cannot be guaranteed. And databases or data sets that include
                            Personal Data may be breached inadvertently or through wrongful intrusion. Upon becoming aware of a data breach, we will notify all affected individuals whose Personal Data
                            may have been compromised as expeditiously as possible after which the breach was discovered.
                        </p>
                        <p>
                            <em>Is data transferred internationally?</em>
                        </p>
                        <p>
                            We may transfer your Personal Data as necessary for the purposes described in this Privacy Policy. In order to provide adequate protection for your Personal Data when it is
                            transferred, we have contractual arrangements regarding such transfers. We take all reasonable technical and organizational measures to protect the Personal Data we
                            transfer.
                        </p>
                        <p>
                            <em>Special Category Data</em>
                        </p>
                        <p>Unless specifically required when using our services and explicit consent is obtained for that service, we do not process special category data.</p>
                        <p>
                            <em>Minors</em>
                        </p>
                        <p>We do not request Personal Data from minors and children and do not knowingly collect such data or pass it on to third parties.</p>
                        <p>
                            <em>Automated decision-making</em>
                        </p>
                        <p>
                            Automated decision-making is the process of making a decision by automated means without any human involvement. Automated decision-making including profiling does not take
                            place.
                        </p>
                        <p>
                            <em>Do Not Sell</em>
                        </p>
                        <p>We do not sell your Personal Data.</p>
                        <p>
                            <strong>Your Rights and Privileges</strong>
                        </p>
                        <p>
                            <em>Privacy rights</em>
                        </p>
                        <p>Under Law no. 190 and GDPR, you can exercise the following rights:</p>
                        <p>
                            ● <em>Right to information</em>
                        </p>
                        <p>
                            ● <em>Right to rectification</em>
                        </p>
                        <p>
                            ● <em>Right to deletion</em>
                        </p>
                        <p>
                            ● <em>Right to data portability</em>
                        </p>
                        <p>
                            ● <em>Right of objection</em>
                        </p>
                        <p>
                            ● <em>Right to withdraw consent</em>
                        </p>
                        <p>
                            ● <em>Right to complain to a supervisory authority</em>
                        </p>
                        <p>
                            ●<em>Right not to be subject to a decision based solely on automated processing.</em>
                        </p>
                        <p>
                            <em></em>
                        </p>
                        <p>If you have any questions about the nature of the Personal Data we hold about you, or if you wish to exercise any of your rights, please contact us.</p>
                        <p>
                            <em>Updating your information</em>
                        </p>
                        <p>
                            If you believe that the information we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion, or object
                            to its processing, please do so by contacting us.
                        </p>
                        <p>
                            <em>Withdrawing your consent</em>
                        </p>
                        <p>
                            You can revoke consents you have given at any time by contacting us. The legality of the data processing carried out until the revocation remains unaffected by the
                            revocation.
                        </p>
                        <p>
                            <em>Access Request</em>
                        </p>
                        <p>
                            In the event that you wish to make a Data Subject Access Request, you may inform us in writing of the same. We will respond to requests regarding access and correction as
                            soon as reasonably possible. Should we not be able to respond to your request within thirty (30) days, we will tell you why and when we will be able to respond to your
                            request. If we are unable to provide you with any Personal Data or to make a correction requested by you, we will tell you why.
                        </p>
                        <p>
                            <em>Complaint to a supervisory authority</em>
                        </p>
                        <p>
                            You have the right to complain about our processing of Personal Data to a supervisory authority responsible for data protection. The supervisory authority in Romania is:
                            The National Supervisory Authority for Personal Data Processing, 28-30 Magheru Blvd, Bucharest, Sector 1, Romania
                            <a href="http://www.dataprotection.ro/">www.dataprotection.ro</a>
                        </p>
                        <p>
                            <strong>Changes and Questions</strong>
                        </p>
                        <p>
                            We may update this policy from time to time. If we make changes, we will revise this policy accordingly and also change the effective date at the end. We encourage you to
                            periodically review this policy. If you would like to contact us regarding this policy, please email boardgamesevents@gmail.com, call +40 788 223 909, or write to us at the
                            above address.
                        </p>
                        <p>
                            <strong>Effective Date</strong>
                        </p>
                        <p>Wednesday, 17 May, 2023</p>
                    </div>
                </div>
            </div>

            <PageFooter />
        </div>
    );
};

export default PrivacyPolicy;
