import { TextField } from "@mui/material";
import { textFieldStyle } from "../../../Data/Interfaces";
import { InputProps } from "./InputProps";

function Input({
  label,
  setValue,
  required,
  error,
  errorMessage,
  style,
  type,
  value,
  multiline,
  rows,
  defaultValue
}: InputProps) {
  return (
    <TextField
      type={type}
      id="outlined-basic"
      label={label}
      required={required}
      error={error}
      helperText={errorMessage}
      variant="outlined"
      className="ButtonSizes"
      multiline={multiline}
      rows={rows}
      style={style}
      value={value}
      defaultValue={defaultValue}
      // scris si background
      sx={textFieldStyle}
      //placeholder
      InputLabelProps={{
        style: {
          fontFamily: "Inter",
          color: "gray",
        },
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default Input;
