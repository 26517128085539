import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { AutocompleteProps } from './AutocompleteProps';
import { textFieldStyle } from '../../../Data/Interfaces';

export const AutocompleteCustom = ({
    value,
    label,
    style,
    setValue,
    required,
    selectedValue,
    setMatchedValue,
}: AutocompleteProps) => {
    let newTextFieldStyle = {
        ...textFieldStyle,
        '& .MuiSvgIcon-root': {
            color: '#FFD15C',
        },
    };

    return (
        <Autocomplete
            disablePortal
            id="autocomplete-box"
            sx={newTextFieldStyle}
            options={value}
            value={selectedValue}
            onInputChange={(e, newInputValue) => {
                if (value.map((val) => val.value).includes(newInputValue)) {
                    setMatchedValue(true);
                } else {
                    setMatchedValue(false);
                }
                setValue({ value: newInputValue, label: newInputValue });
            }}
            ListboxProps={{
                style: {
                    backgroundColor: '#383838',
                    color: 'gray',
                },
            }}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    id="outlined-select"
                    required={required}
                    label={label}
                    style={style}
                    InputLabelProps={{
                        style: {
                            fontFamily: 'Inter',
                            color: 'gray',
                        },
                    }}
                    SelectProps={{
                        style: {
                            fontFamily: 'Inter',
                            backgroundColor: '#383838',
                            color: 'gray',
                        },
                    }}
                />
            )}
        />
    );
};
