import { AddPlayerRequest } from "../Components/event/tabPlayers/types/AddPlayerRequest";
import { UpsertTeam } from "../Components/event/tabTeams/types/TeamData";
import { AccountJWT } from "../Components/utils/userContext/UserContextProps";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

export const getTeamsApi = (eventCode: string, session: AccountJWT | null) => {
  return axios.get(BASE_URL + `/events/${eventCode}/teams`, {
    headers: {
      Authorization: session?.idToken.getJwtToken(),
    },
  });
};

export const confirmTeamsApi = (
  eventCode: string,
  data: unknown,
  session: AccountJWT | null
) => {
  return axios.put(BASE_URL + `/events/${eventCode}/teams`, data, {
    headers: {
      Authorization: session?.idToken.getJwtToken(),
    },
  });
};

export const removeTeamsApi = (
  eventCode: string,
  data: unknown,
  session: AccountJWT | null
) => {
  return axios.delete(BASE_URL + `/events/${eventCode}/teams`, {
    headers: {
      Authorization: session?.idToken.getJwtToken(),
    },
    data,
  });
};

export const upsertTeamApi = (
  eventCode: string,
  data: UpsertTeam,
  session: AccountJWT | null
) => {
  return axios.post(BASE_URL + `/events/${eventCode}/teams`, data, {
    headers: {
      Authorization: session?.idToken.getJwtToken(),
    },
  });
};
