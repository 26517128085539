import { MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { textFieldStyle } from "../../../Data/Interfaces";
import { InputSelectProps } from "./InputSelectProps";


function InputSelect({
  value,
  selectedValue,
  label,
  setValue,
  required,
  style,
}: InputSelectProps) {
    let newTextFieldStyle = {
      ...textFieldStyle,
      '& .MuiSvgIcon-root': {
        color: '#FFD15C',
     }
    }
  return (
    <TextField
      id="outlined-select"
      select
      required={required}
      label={label}
      value={selectedValue}
      onChange={(e) => setValue(e.target.value)}
      sx={newTextFieldStyle}
      style={style}
      //placeholder
      InputLabelProps={{
        style: {
          fontFamily: "Inter",
          color: "gray",
        },
      }}
      SelectProps={{
        style: {
          fontFamily: "Inter",
          backgroundColor: "#383838",
          color: "gray",
        },
      }}
      inputProps={{
        MenuProps: {
            MenuListProps: {
                sx: {
                    backgroundColor: '#383838'
                }
            }
        }
    }}
      //style={{ width: "284px", height: "52px", marginBottom: "20px" }}
    >
      {value.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          style={ selectedValue === option.value ? { color: "gray", backgroundColor: "#383838" } : { color: "gray", backgroundColor: "#383838"}}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default InputSelect;
