import { Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import "../../css/CreateEventPage.css";
import "../../App.css";
import Input from "../utils/input/Input";
import SwitchToggle from "../utils/switch/SwitchToggle";
import DialogMenu from "../utils/dialogMenu/DialogMenu";
import { useMutation, useQuery } from "react-query";
import { createEventApi } from "../../network/eventapi";
import { EventDataRequest, EventDataResponse } from "./types";
import { SessionProps } from "./types/SessionProps";

import { eventSystems } from "../../Data/eventsData";
import InputSelect from "../utils/inputSelect/InputSelect";

export const CreateEvent: FC<SessionProps> = ({session}) => {
    const [location, setLocation] = useState("");
    const [eventGame, setEventGame] = useState("");
    const [rounds, setRounds] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [submitCheck, setSubmitCheck] = useState(false);
    const [adminApproval, setAdminApproval] = useState(false);
    const [onlyOrganizerAccess, setOnlyOrganizerAccess] = useState(false);
    const [pairWinners, setPairWinners] = useState(false);
    const [createdEvent, setCreatedEvent] = useState(false);
    const [reload, setReload] = useState(false);
    const [eventCode, setEventCode] = useState("");
    const [birthdateError, setBirthdateError] = useState(false);
    const [eventSystem, setEventSystem] = useState("");
    const [teamEvent, setTeamEvent] = useState(false);

    const { mutate, isSuccess, isLoading, isError } = useMutation((variables: EventDataRequest) => createEventApi(variables, session), {
        onSuccess: (response: {data: EventDataResponse}) => {
            setEventCode(response.data.eventCode);
            setCreatedEvent(prev => !prev);
        },
    });

    useEffect(() => {
        if (reload === true) {
            window.location.reload();
        }
    }, [reload]);

    const onSubmit = (e: any) => {
        e.preventDefault();

        const eventData: EventDataRequest = {
            location: location,
            eventName: `${eventGame}@${location}`,
            numberOfRounds: rounds,
            eventDate: `${day}-${month}-${year}-20-00`,
            pairWinners: pairWinners,
            onlyOrganizerAccess: onlyOrganizerAccess,
            eventSystem: eventSystem,
            teamEvent: teamEvent,
        };

        mutate(eventData);
    };

    useEffect(() => {
        if (day.length !=2 && day.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [day]);

    useEffect(() => {
        if (month.length != 2 && month.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [month]);

    useEffect(() => {
        if (year.length != 4 && year.length > 0) {
            setBirthdateError(true);
            setSubmitCheck(true);
        } else {
            setBirthdateError(false);
            setSubmitCheck(false);
        }
    }, [year]);

    return (
        <>
            <div className="IntroCreateEvent">Create Event</div>
            <DialogMenu isOpen={createdEvent} setOpen={setReload} description={`Event successfully created. Event code: ${eventCode}`} />
            <form onSubmit={onSubmit} className="FormCss">
                <div className="CreateEventDateDiv">Date</div>
                <div className="CreateEventDate">
                    <Input
                        label="DD"
                        setValue={setDay}
                        error={birthdateError}
                        required
                        style={{
                            marginRight: "15px",
                        }}
                    />
                    <Input
                        label="MM"
                        setValue={setMonth}
                        error={birthdateError}
                        required
                        style={{
                            marginRight: "15px",
                        }}
                    />
                    <Input label="YYYY" setValue={setYear} error={birthdateError} required />
                </div>

                <Input
                    label="Location"
                    setValue={setLocation}
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                />

                <Input
                    label="Event Game"
                    setValue={setEventGame}
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                />

                <InputSelect value={eventSystems} selectedValue={eventSystem} required label="Event System" setValue={setEventSystem} style={{ width: "284px", height: "52px", marginBottom: "20px" }} />

                <Input
                    label="No Of Rounds"
                    setValue={setRounds}
                    type="number"
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                />
                <SwitchToggle label="Pair Winners" setToggle={setPairWinners} checked={pairWinners}/>

                <SwitchToggle label="Only Organizer Access" setToggle={setOnlyOrganizerAccess} checked={onlyOrganizerAccess}/>

                <SwitchToggle label="Team Event" setToggle={setTeamEvent} checked={teamEvent}/>

                {/* <SwitchToggle label="Admin Approval" setToggle={setAdminApproval} /> */}

                {/* <SwitchToggle label="Round Timer" setToggle={setRoundTimer} />

        {roundTimer && (
          <Input
            label="Time"
            setValue={setTimer}
            required
            style={{
              marginBottom: "20px",
            }}
          />
        )}
        <SwitchToggle label="Age Pairing" setToggle={setAgePairing} />

        <SwitchToggle
          label="Experience Pairing"
          setToggle={setExperiencePairing}
        /> */}

                <Button
                    disabled={submitCheck}
                    variant="contained"
                    sx={{
                        backgroundColor: "#FFD15C",
                        fontFamily: "Inter",
                        fontWeight: "600",
                        fontSize: "12px",
                        color: "black",
                        width: "284px",
                        height: "52px",
                        "&:hover": {
                            backgroundColor: "#FFD15C",
                            color: "#262626",
                        },
                    }}
                    type="submit"
                >
                    Create Event
                </Button>
            </form>
        </>
    );
}

export default CreateEvent;
