import { useEffect, useState, useContext, useRef } from 'react';
import Loading from '../utils/loading/Loading';
import Navbar from '../utils/navbar/Navbar';
import { AccountContext } from '../utils/userContext/UserContext';
import logo from '../../Images/boardgame.png';
import '../../css/EventsPage.css';
import '../../css/TabPlayers.css';
import '../../css/PlayEventTabs.css';
import TabPlayers from './tabPlayers/TabPlayers';
import TabTeams from './tabTeams/TabTeams';
import TabRounds from './tabRounds/TabRounds';
import { Leaderboard } from './tabLeaderboard/Leaderboard';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EventDataResponse } from './types';
import { WS_URL, getEventApi, joinEventApi } from '../../network/eventapi';
import { useLocation, useNavigate } from 'react-router-dom';
import PageFooter from '../utils/footer/PageFooter';
import edit from '../../Images/settings.png';
import { FormDialogEventSettings } from '../utils/formDialog/FormDialogEventSettings';

function PlayEvent() {
    const { getSession, session, isLoading: sessionLoading } = useContext(AccountContext);
    const navigate = useNavigate();
    const [addCloseButton, setAddCloseButton] = useState(false);
    const [menuOption, setMenuOption] = useState('players');
    const [eventData, setEventData] = useState({} as unknown as EventDataResponse);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isTeamEvent, setIsTeamEvent] = useState(false);
    const playersRef = useRef(null);
    const teamsRef = useRef(null);
    const roundsRef = useRef(null);
    const leaderboardRef = useRef(null);
    const queryClient = useQueryClient();
    const [openDialog, setOpenDialog] = useState(false);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null as unknown as boolean);
    const wsClientRef = useRef(null as unknown as WebSocket);

    const location = useLocation();
    const [eventCode, setEventCode] = useState(location.pathname.split('/')[2]);

    const { mutate: joinEvent, isLoading: joinLoading } = useMutation(() => joinEventApi(eventCode, session), {
        onSuccess: (response: { data: any }) => {
            setEventData(response.data);
            if (
                response.data.eventAdmin === session?.idToken.payload[`cognito:username`] ||
                session?.idToken.payload[`cognito:username`] === 'revoplays' ||
                session?.idToken.payload[`cognito:username`] === 'IdealBoardGames'
            ) {
                setIsAdmin(true);
            }

            if (response.data.teamEvent) {
                setIsTeamEvent(true);
            }
        },
        onError: (err: { response: { data: { message: string } } }) => {
            if (err?.response?.data?.message === 'Forbidden') {
                navigate('/events');
            }
        },
    });

    useQuery([`get-event`, eventCode], () => getEventApi(eventCode, session), {
        enabled: !!session,
        onSuccess: (response: { data: any }) => {
            setEventData(response.data);
            if (
                response.data.eventAdmin === session?.idToken.payload[`cognito:username`] ||
                session?.idToken.payload[`cognito:username`] === 'revoplays'
            ) {
                setIsAdmin(true);
            }
        },
    });

    useEffect(() => {
        setEventCode(location.pathname.split('/')[2]);
    }, [location.pathname]);

    useEffect(() => {
        const fetchSession = async () => {
            await getSession();
        };

        if (waitingToReconnect) {
            return;
        }

        let websocket: WebSocket;

        if (!wsClientRef.current) {
            fetchSession()
                .then(() => {
                    if (!session) {
                        return;
                    }
                    websocket = new WebSocket(
                        `${WS_URL}/?Authorizer=${session?.idToken.getJwtToken()}&userName=${
                            session?.idToken.payload['cognito:username']
                        }`
                    );

                    wsClientRef.current = websocket;

                    websocket.onerror = (event) => console.log(event);
                    websocket.onopen = () => {
                        joinEvent();
                        // websocket.send("{\"action\": \"ping\", \"data\": \"ping\"}");
                    };

                    websocket.onmessage = (event) => {
                        const data = JSON.parse(event.data);
                        const queryKey = [...data.entity]?.filter(Boolean);
                        queryClient.invalidateQueries({ queryKey });
                    };

                    websocket.onclose = (event) => {
                        if (wsClientRef.current) {
                            // Connection failed
                            console.log('ws closed by server');
                        } else {
                            // Cleanup initiated from app side, can return here, to not attempt a reconnect
                            console.log('ws closed by app component unmount');
                            return;
                        }

                        if (waitingToReconnect) {
                            return;
                        }

                        setWaitingToReconnect(true);

                        setTimeout(() => setWaitingToReconnect(null as unknown as boolean), 1000);
                    };
                })
                .catch(() => {
                    if (!session) {
                        navigate('/login');
                    }
                });

            return () => {
                console.log('Cleanup');
                // Dereference, so it will set up next time
                wsClientRef.current = null as unknown as WebSocket;

                websocket?.close();
            };
        }
    }, [waitingToReconnect]);

    const handleChange = (event: any) => {
        console.log(event.currentTarget.id);
        if (event.currentTarget.id === (playersRef.current as any).id) {
            changeClass(playersRef.current, 'PlayEventNavbarOptionSelected', 'PlayEventNavbarOptionNotSelected');

            changeClass(roundsRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            changeClass(leaderboardRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            if (isTeamEvent)
                changeClass(teamsRef?.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
        } else if (event.currentTarget.id === (teamsRef?.current as any)?.id) {
            if (isTeamEvent)
                changeClass(teamsRef.current, 'PlayEventNavbarOptionSelected', 'PlayEventNavbarOptionNotSelected');

            changeClass(roundsRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            changeClass(playersRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            changeClass(leaderboardRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
        } else if (event.currentTarget.id === (roundsRef.current as any)?.id) {
            changeClass(roundsRef.current, 'PlayEventNavbarOptionSelected', 'PlayEventNavbarOptionNotSelected');

            changeClass(playersRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            changeClass(leaderboardRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            if (isTeamEvent)
                changeClass(teamsRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
        } else if (event.currentTarget.id === (leaderboardRef.current as any).id) {
            changeClass(leaderboardRef.current, 'PlayEventNavbarOptionSelected', 'PlayEventNavbarOptionNotSelected');

            changeClass(roundsRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            changeClass(playersRef.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
            if (isTeamEvent)
                changeClass(teamsRef?.current, 'PlayEventNavbarOptionNotSelected', 'PlayEventNavbarOptionSelected');
        }

        setMenuOption(event.currentTarget.id);
    };

    const changeClass = (element: any, toAddClass: string, toRemoveClass: string) => {
        element.classList.add(toAddClass);
        element.classList.remove(toRemoveClass);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (joinLoading || sessionLoading) {
        return <Loading isLoading={joinLoading} />;
    }

    return (
        <div className="EventsPage">
            <a href="/events">
                <img className="LogoDesktop" src={logo} alt="Logo" />
            </a>
            <div className="EventsPageWeb">
                <>
                    <FormDialogEventSettings
                        eventData={eventData}
                        setEventData={setEventData}
                        openDialog={openDialog}
                        handleClose={handleCloseDialog}
                        session={session}
                    />
                    <Navbar closeButton isOpen={addCloseButton} setOpen={setAddCloseButton} />
                    <div className="MarginTop15" style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginRight: '10px',
                            }}
                        >
                            <span>{eventData.eventName}</span>
                            <span>{eventData.eventCode}</span>
                        </div>
                        <div>
                            {isAdmin && (
                                <a>
                                    <img
                                        className="SettingsIcon"
                                        src={edit}
                                        onClick={() => {
                                            setOpenDialog(true);
                                        }}
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="PlayEventNavbar">
                        <button
                            ref={playersRef}
                            id="players"
                            className="PlayEventNavbarOption PlayEventNavbarOptionSelected"
                            onClick={(event) => handleChange(event)}
                        >
                            PLAYERS
                        </button>
                        {isTeamEvent && (
                            <button
                                ref={teamsRef}
                                id="teams"
                                className="PlayEventNavbarOption PlayEventNavbarOptionNotSelected"
                                onClick={(event) => handleChange(event)}
                            >
                                TEAMS
                            </button>
                        )}
                        <button
                            ref={roundsRef}
                            id="rounds"
                            className="PlayEventNavbarOption PlayEventNavbarOptionNotSelected"
                            onClick={(event) => handleChange(event)}
                        >
                            ROUNDS
                        </button>
                        <button
                            ref={leaderboardRef}
                            id="leaderboard"
                            className="PlayEventNavbarOption PlayEventNavbarOptionNotSelected"
                            onClick={(event) => handleChange(event)}
                        >
                            LEADERBOARD
                        </button>
                    </div>
                    {menuOption === 'players' && (
                        <TabPlayers eventData={eventData as EventDataResponse} isAdmin={isAdmin} session={session} />
                    )}
                    {menuOption === 'teams' && (
                        <TabTeams eventData={eventData as EventDataResponse} isAdmin={isAdmin} session={session} />
                    )}
                    {menuOption === 'rounds' && (
                        <TabRounds eventData={eventData as EventDataResponse} isAdmin={isAdmin} session={session} />
                    )}
                    {menuOption === 'leaderboard' && (
                        <Leaderboard eventData={eventData as EventDataResponse} isAdmin={isAdmin} session={session} />
                    )}
                </>
            </div>
            <PageFooter />
        </div>
    );
}

export default PlayEvent;
