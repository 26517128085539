import { AddPlayerRequest } from "../Components/event/tabPlayers/types/AddPlayerRequest";
import { AccountJWT } from "../Components/utils/userContext/UserContextProps";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

export const getPlayersApi = (eventCode: string, session: AccountJWT | null) => {
    return axios.get(BASE_URL + `/events/${eventCode}/players`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const confirmPlayersApi = (eventCode: string, data: unknown, session: AccountJWT | null) => {
    return axios.put(BASE_URL + `/events/${eventCode}/players`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};

export const removePlayersApi = (eventCode: string, data: unknown, session: AccountJWT | null) => {
    return axios.delete(BASE_URL + `/events/${eventCode}/players`, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
        data,
    });
};

export const addPlayerApi = (eventCode: string, data: AddPlayerRequest, session: AccountJWT | null) => {
    return axios.post(BASE_URL + `/events/${eventCode}/players`, data, {
        headers: {
            Authorization: session?.idToken.getJwtToken(),
        },
    });
};