import { ChangeEvent, useContext, useEffect, useState } from 'react';
import Navbar from '../utils/navbar/Navbar';
import '../../css/ProfilePage.css';
import { useMutation, useQuery } from 'react-query';
import { getUserApi } from '../../network/profileapi';
import { Box, Typography, Pagination } from '@mui/material';
import Collapsible from 'react-collapsible';
import { AccountContext } from '../utils/userContext/UserContext';
import Loading from '../utils/loading/Loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import triangle from '../../Images/triangle.png';
import { Button } from 'react-bootstrap';
import { FormDialogEditProfile } from '../utils/formDialog/FormDialogEditProfile';
import PageFooter from '../utils/footer/PageFooter';
import logo from '../../Images/boardgame.png';
import { OrganizedEvent, PastEvent, ProfileData } from './types/ProfileData';
import { EventData } from './types/ProfileData';

function Profile() {
    const { getSession, session } = useContext(AccountContext);
    const navigate = useNavigate();
    const [addCloseButton, setAddCloseButton] = useState(false);
    const [pastEvents, setPastEvents] = useState([] as unknown as PastEvent[]);
    const [organizedEvents, setOrganizedEvents] = useState([] as unknown as OrganizedEvent[]);
    const [description, setDescription] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const [username, setUsername] = useState(location.pathname.split('/')[2]);
    const [page, setPage] = useState('1');

    useEffect(() => {
        setUsername(location.pathname.split('/')[2]);
    }, [location.pathname]);

    const { data, isLoading, error, refetch } = useQuery(
        ['get-user', username],
        () => getUserApi(username, page, session),
        {
            enabled: !!session,
        }
    );

    const { mutate: getEvents, isLoading: loadingEvents } = useMutation(
        (page: string) => getUserApi(username, page, session),
        {
            onSuccess: (response: { data: ProfileData }) => {
                setPastEvents(response?.data.pastEvents);
                setOrganizedEvents(response?.data.organizedEvents);
                setDescription(response?.data.description);
            },
        }
    );

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangePage = (e: ChangeEvent<unknown>, value: number) => {
        setPage(value?.toString());

        getEvents(value?.toString());
    };

    useEffect(() => {
        const fetchSession = async () => {
            await getSession();
        };

        fetchSession().catch(() => {
            if (!session) {
                navigate('/login');
            }
        });
    }, []);

    useEffect(() => {
        if (data) {
            setPastEvents(data.data.pastEvents);
            setOrganizedEvents(data.data.organizedEvents);
            setDescription(data.data.description);
        }
    }, [data]);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (loadingEvents) {
        return <Loading isLoading={loadingEvents} />;
    }

    return (
        <div className="EventsPage">
            <a href="/events">
                <img className="LogoDesktop" src={logo} alt="Logo" />
            </a>
            <FormDialogEditProfile
                description={description}
                setDescription={setDescription}
                username={username}
                openDialog={openDialog}
                handleClose={handleCloseDialog}
                session={session}
            />

            <div className="EventsPageWeb">
                <Navbar closeButton isOpen={addCloseButton} setOpen={setAddCloseButton} />
                <h1 className="ProfileHeader">Profile</h1>
                <span>
                    <span className="ProfileUsername">{username}</span>{' '}
                    {username === session?.idToken.payload['cognito:username'] && (
                        <a
                            className="EditProfileLink"
                            onClick={() => {
                                handleOpenDialog();
                            }}
                        >
                            (Edit)
                        </a>
                    )}
                </span>

                <p className="ProfileDescription">{description}</p>

                <div className="TabContent">
                    <Box>
                        {isLoading ? (
                            <Loading isLoading={isLoading} />
                        ) : (
                            <>
                                <div className="PastEvents">Past Events</div>
                                {pastEvents.length > 0 ? (
                                    <>
                                        {pastEvents.map(
                                            (
                                                event: {
                                                    eventName: string;
                                                    eventDate: string;
                                                    code: string;
                                                    location: string;
                                                    game: string;
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <Collapsible
                                                        key={index}
                                                        classParentString="UserEventsCollapsible"
                                                        trigger={
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '40px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Event Code: {event.code}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Date: {event.eventDate.substring(0, 10)}
                                                                </Typography>
                                                                <a>
                                                                    <img className="ProfileArrow" src={triangle} />
                                                                </a>
                                                            </Box>
                                                        }
                                                    >
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    height: '40px',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Location: {event.location}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Event Game: {event.game}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    justifyContent: 'flex-end',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                <a>
                                                                    <Button
                                                                        style={{
                                                                            borderRadius: '5px',
                                                                            backgroundColor: '#FFD15C',
                                                                            color: '#383838',
                                                                            fontWeight: 600,
                                                                            fontSize: '12px',
                                                                            fontFamily: 'Inter',
                                                                            padding: '4px 18px',
                                                                        }}
                                                                        onClick={() => {
                                                                            navigate(`/events/${event.code}`);
                                                                        }}
                                                                    >
                                                                        Go to event
                                                                    </Button>
                                                                </a>
                                                            </Box>
                                                        </Box>
                                                    </Collapsible>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <div className="AdminApproval">No past events yet.</div>
                                )}

                                <div className="PastEvents">Organized Events</div>
                                {organizedEvents.length > 0 ? (
                                    <>
                                        {organizedEvents.map(
                                            (
                                                event: {
                                                    eventName: string;
                                                    eventDate: string;
                                                    code: string;
                                                    location: string;
                                                    game: string;
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <Collapsible
                                                        key={index}
                                                        classParentString="UserEventsCollapsible"
                                                        trigger={
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '40px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Event Code: {event.code}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Date: {event.eventDate.substring(0, 10)}
                                                                </Typography>
                                                                <a>
                                                                    <img className="ProfileArrow" src={triangle} />
                                                                </a>
                                                            </Box>
                                                        }
                                                    >
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    height: '40px',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Location: {event.location}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        fontFamily: 'Inter',
                                                                    }}
                                                                >
                                                                    Event Game: {event.game}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    justifyContent: 'flex-end',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{
                                                                        borderRadius: '5px',
                                                                        backgroundColor: '#FFD15C',
                                                                        color: '#383838',
                                                                        fontWeight: 600,
                                                                        fontSize: '12px',
                                                                        fontFamily: 'Inter',
                                                                        padding: '4px 18px',
                                                                    }}
                                                                    onClick={() => {
                                                                        navigate(`/events/${event.code}`);
                                                                    }}
                                                                >
                                                                    Go to event
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Collapsible>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <div className="AdminApproval">No organized events yet.</div>
                                )}
                            </>
                        )}
                    </Box>
                </div>

                <span className="MarginTop50">
                    {page !== undefined && (
                        <Pagination
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    color: 'white', // Change this to your desired text color
                                },
                            }}
                            page={parseInt(page)}
                            count={10}
                            size="small"
                            color="primary"
                            onChange={handleChangePage}
                        />
                    )}
                </span>
            </div>

            <PageFooter />
        </div>
    );
}

export default Profile;
