import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useState } from "react";
import Input from "../input/Input";
import FormDialogButton from "./FormDialogButton";
import { useMutation } from "react-query";
import { UpdateRoundRequest } from "../../event/tabRounds/types/UpdateRoundRequest";
import { updateRoundApi } from "../../../network/roundsapi";
import { AccountJWT } from "../userContext/UserContextProps";
import Loading from "../loading/Loading";

interface AddPlayerRoundPropTypes {
    eventCode: string;
    roundNumber: string;
    tableNumber: string;
    openDialog: boolean;
    handleClose: any;
    session: AccountJWT | null;
}

export const FormDialogRoundPlayer: FC<AddPlayerRoundPropTypes> = ({ tableNumber, roundNumber, openDialog, handleClose, eventCode, session }) => {
    const [username, setUsername] = useState("");
    const { mutate: addPlayerToTableRound } = useMutation((variables: UpdateRoundRequest) => updateRoundApi(eventCode, variables, session));

    const handleAddPlayer = (e: any) => {
        e.preventDefault();

        let data: UpdateRoundRequest = {
            roundNumber: roundNumber,
            tableNumber: tableNumber,
            items: {}
        }

        data.items[`player#${username}`] = {isWinner: false, username: username, tableNumber: tableNumber, points: 0}
        addPlayerToTableRound(data);
        
        handleClose();
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#383838" } }}>
                <DialogTitle style={{ display: "flex", justifyContent: "center", color: "#FFD15C", fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "16px" }}>
                    Add player to {tableNumber} in {roundNumber}
                </DialogTitle>
                <form onSubmit={handleAddPlayer}>
                    <DialogContent style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                        <Input label="Username" required setValue={setUsername} />
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                        <FormDialogButton label="Add" />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
