import { Box, Button, Input, Link, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import check from "../../../Images/check.png";
import close from "../../../Images/cross.png";
import { AccountJWT } from "../../utils/userContext/UserContextProps";
import { PlayerData } from "./types/PlayerData";
import { DialogMenuAction } from "../../utils/dialogMenu/DialogMenuAction";

interface TableRowTypes {
    playerData: PlayerData;
    eventCode: string;
    roundNumber: string;
    tableNumber: string;
    session: AccountJWT | null;
    isAdmin: boolean;
    removePlayers: Function;
    updatePlayerPoints: Function;
    updatePlayerIsWinner: Function;
}

export const TableRow: FC<TableRowTypes> = ({ playerData, eventCode, roundNumber, tableNumber, session, isAdmin, removePlayers, updatePlayerPoints, updatePlayerIsWinner }) => {
    const [openRemovePlayerDialog, setOpenRemovePlayerDialog] = useState(false);
    const modalDescription = `Are you sure you want to remove ${playerData.username} from this table?`;

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                alignItems: "center",
            }}
        >
            <DialogMenuAction
                isOpen={openRemovePlayerDialog}
                setOpen={setOpenRemovePlayerDialog}
                description={modalDescription}
                handleAction={() => {
                    removePlayers(playerData.username);
                    setOpenRemovePlayerDialog(false);
                }}
            />
            <Box>
                <Typography sx={{ fontSize: "12px" }}>{playerData.username}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Link onClick={() => updatePlayerIsWinner(playerData.username, !playerData.isWinner)}>
                    <Box
                        sx={{
                            display: "flex",
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#383838",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {playerData.isWinner ? <img src={check} alt="check" /> : null}
                    </Box>
                </Link>
                <TextField
                    variant="standard"
                    type="number"
                    InputProps={{
                        disableUnderline: true, // <== added this
                        style: {
                            color: "#FFD15C",
                            fontSize: "12px",
                            fontWeight: 600,
                            display: "flex",
                        },
                    }}
                    inputProps={{
                        style: {
                            textAlign: "center",
                        },
                    }}
                    sx={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#383838",
                        marginLeft: 3,

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    value={playerData.points}
                    onChange={(e) => {
                        updatePlayerPoints(playerData.username, e.target.value);
                    }}
                />
                {isAdmin && (
                    <Link onClick={() => setOpenRemovePlayerDialog(true)}>
                        <Box
                            sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "transparent",
                                display: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: 3,
                            }}
                        >
                            <img className="NavbarCross" src={close} alt="Logo" />
                        </Box>
                    </Link>
                )}
            </Box>
        </Box>
    );
};
