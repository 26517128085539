import "../../../css/TabPlayers.css";
import { FC } from "react";
import { PlayerData, PlayersProps } from "./types/PlayerData";
import { Link } from "react-router-dom";

export const TabPlayersUser: FC<PlayersProps> = ({playersData, eventData}) => {
  return (
    <>
      {eventData.adminApproval && (
        <div className="AdminApproval">Event requires Organizer's Approval</div>
      )}

      <div className="TablePlayers">
        {playersData.map((row: PlayerData) => (
          <div key={row.username} className="TablePlayersRow">
            <Link to={`/users/${row.username}`} className="TableCell">{row.username}</Link>
            <div>
              {row.isConfirmed ? (
                <div className="JoinedCell">JOINED</div>
              ) : (
                <div className="PendingCell">PENDING..</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default TabPlayersUser;
