export const textFieldStyle = {
    marginBottom: "20px",
    input: {
        color: "gray",
        backgroundColor: "#383838",
        borderRadius: "5px",
        fontFamily: "Inter",
        borderColor: "#ffd15c",
    },
    "& .MuiOutlinedInput-root": {
        "& > fieldset": { borderColor: "#FFD15C", borderWidth: "2px" },
        "&.Mui-focused fieldset": {
            borderColor: "#ffd15c",
        },
        "&:hover fieldset": {
            borderColor: "#ffd15c",
        },
        "&.Mui-error &.Mui-focused fieldset": {
            borderColor: "#FF5C5C",
        },
    },
    "& .MuiInputBase-multiline": {
        color: "gray"
    },
};
